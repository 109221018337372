import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280 1280">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  >


<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M683.000000,1281.000000 
	C455.333344,1281.000000 228.166672,1281.000000 1.000000,1281.000000 
	C1.000000,854.333313 1.000000,427.666656 1.000000,1.000000 
	C427.666656,1.000000 854.333313,1.000000 1281.000000,1.000000 
	C1281.000000,427.666656 1281.000000,854.333313 1281.000000,1281.000000 
	C1081.833374,1281.000000 882.666687,1281.000000 683.000000,1281.000000 
M625.253723,260.801636 
	C625.253723,260.801636 624.977661,261.003082 624.274536,261.098785 
	C622.529236,262.732086 620.783997,264.365387 618.834290,265.157898 
	C618.907776,263.437500 618.981262,261.717102 619.391296,259.807220 
	C619.246094,259.540070 619.100952,259.272919 618.729614,258.771576 
	C618.729614,258.771576 618.816345,258.457764 618.835632,257.600555 
	C614.905579,254.954315 610.955261,255.051788 606.469299,257.787018 
	C606.290100,257.864746 606.110840,257.942444 605.226562,257.987610 
	C604.524597,257.947601 603.822693,257.907593 602.848816,257.108337 
	C601.338379,256.739716 599.833496,256.081604 598.316589,256.052887 
	C591.980286,255.933090 585.632935,255.824844 579.305664,256.091492 
	C576.846802,256.195129 574.430115,257.301453 572.002441,258.002319 
	C572.002441,258.002319 571.951172,257.994141 571.242004,257.992065 
	C570.770142,258.609406 569.889771,259.225372 569.887634,259.844330 
	C569.856567,268.924805 569.963196,278.005646 569.994629,287.086517 
	C570.025513,296.014923 570.004395,296.014984 560.917236,295.077820 
	C560.889160,282.814789 560.861084,270.551727 560.798767,257.457886 
	C559.553406,256.973114 558.249756,255.964539 557.072815,256.095306 
	C552.286865,256.627167 547.539856,257.508392 542.830261,258.248230 
	C542.830261,258.248230 542.788208,258.256470 542.797363,257.423157 
	C539.069458,255.134460 532.467773,255.402634 529.986877,258.009491 
	C529.986877,258.009491 529.922180,257.994690 529.394287,258.168701 
	C528.507202,258.169159 527.620117,258.169586 526.844482,258.156616 
	C526.844482,258.156616 526.790833,258.255188 526.798950,257.421692 
	C523.076721,255.138870 516.480896,255.401764 513.836487,258.342438 
	C513.709473,258.617584 513.524170,258.845215 512.420776,259.012268 
	C511.274445,259.011078 510.128082,259.009888 508.734772,258.769989 
	C508.734772,258.769989 508.824097,258.438354 508.828125,257.583984 
	C504.868713,254.856552 500.875488,255.231155 496.625427,257.500122 
	C496.625427,257.500122 496.481140,257.306793 495.630157,257.230286 
	C495.087372,258.772156 494.095673,260.307861 494.074463,261.856903 
	C493.933441,272.151794 494.068298,282.450439 493.929535,292.745392 
	C493.907410,294.389771 493.315613,297.177063 492.382233,297.463257 
	C487.867371,298.847778 488.862091,302.306213 489.147461,305.222839 
	C489.455780,308.374054 491.099091,310.800903 494.763550,311.192719 
	C495.465546,311.267761 496.069885,312.255707 496.719299,312.822510 
	C494.577393,313.994202 492.696869,313.990692 490.816345,313.994995 
	C487.887115,314.001740 484.911041,313.689301 482.040131,314.103363 
	C478.208618,314.655945 477.223328,317.984985 479.917145,321.804993 
	C479.746613,323.185699 479.576080,324.566406 478.711884,326.013306 
	C478.140594,326.876831 477.141205,327.704010 477.064240,328.609558 
	C476.287292,337.751831 475.559814,346.900940 475.023224,356.060150 
	C474.548126,364.169891 474.471466,372.303558 473.962799,380.410583 
	C473.470215,388.261169 472.528229,396.083130 472.011047,403.932648 
	C471.520905,411.371735 471.155121,418.827667 471.041443,426.280853 
	C470.877533,437.024323 471.357086,437.384613 482.004486,438.035065 
	C483.473236,438.124786 485.173767,439.235382 486.322266,438.771759 
	C488.886108,437.736786 492.745392,436.425751 493.264160,434.478027 
	C494.689484,429.127197 493.781067,423.491974 496.529236,417.981293 
	C498.888519,413.250397 499.111908,407.351257 499.793884,401.907867 
	C501.316711,389.752808 511.272858,382.618378 516.827332,372.876434 
	C517.839355,371.101471 521.307434,370.688538 522.236877,368.911896 
	C525.383667,362.896698 531.874695,362.066986 536.839294,359.143616 
	C540.706238,356.866577 545.809204,356.476440 550.441895,355.756531 
	C559.672424,354.322113 568.933777,352.452515 578.224731,352.174042 
	C596.860046,351.615448 615.523804,351.963226 634.175537,352.030701 
	C639.333435,352.049347 642.049927,356.291351 641.918030,361.217804 
	C641.554871,374.786102 641.929810,388.372314 641.781616,401.948944 
	C641.700500,409.383270 641.810547,416.919373 640.627747,424.213928 
	C639.452759,431.459595 643.679382,437.968109 650.995728,437.995361 
	C655.991699,438.013977 660.987854,437.998169 665.983887,437.998962 
	C671.726929,437.999878 677.479065,437.798157 683.210693,438.054688 
	C690.607605,438.385773 697.989807,439.492096 705.375061,439.462341 
	C709.442993,439.445984 713.551697,437.943726 717.533691,436.750244 
	C718.472778,436.468750 719.482117,434.778259 719.586243,433.655090 
	C719.934082,429.900391 720.046936,426.102448 719.892029,422.335785 
	C719.845459,421.203979 718.631042,420.120209 717.844910,418.211700 
	C717.869995,417.132721 717.895020,416.053741 718.727600,414.830505 
	C719.148499,413.174957 719.925964,411.521362 719.935059,409.863586 
	C720.026367,393.200623 719.989075,376.537018 719.993896,359.873566 
	C719.995667,353.604095 721.541321,352.022461 727.861572,352.009888 
	C741.858826,351.982086 755.896057,352.665222 769.843018,351.827057 
	C785.963379,350.858307 801.441589,354.284424 817.028259,357.003723 
	C822.228455,357.910980 826.929382,361.922211 832.114197,362.623383 
	C842.196045,363.986847 844.567932,374.896332 852.938660,378.048615 
	C853.637268,378.311707 853.912415,379.728821 854.360535,380.621765 
	C855.417908,382.729126 856.321655,384.928192 857.539429,386.937408 
	C859.903137,390.837341 864.753845,394.888214 864.364197,398.410828 
	C863.776062,403.727875 867.184204,406.558319 868.113708,410.617188 
	C868.855408,413.856354 867.601685,417.550201 868.332703,420.794586 
	C869.473450,425.856781 870.470459,431.605377 873.589172,435.343933 
	C875.762451,437.949127 881.386169,437.675873 885.468506,438.688629 
	C885.629944,438.728668 885.788818,438.786499 885.952515,438.808685 
	C890.245300,439.390015 897.368591,432.194214 896.515076,428.259735 
	C895.244019,422.400269 893.392395,416.550415 893.065369,410.625946 
	C892.315002,397.029083 892.490112,383.383911 891.985779,369.769592 
	C891.695068,361.922913 891.271912,354.027496 890.080322,346.281372 
	C888.585022,336.560791 888.305969,326.994049 889.977173,319.012207 
	C888.729431,315.011078 887.892883,312.328491 886.847473,308.976135 
	C884.237488,315.403870 878.935181,314.873627 873.138245,312.347870 
	C874.402649,311.232788 875.901550,310.594086 877.345581,310.700562 
	C879.879395,310.887482 882.515808,311.815063 882.983276,307.975616 
	C882.983276,307.975616 882.938965,307.978424 883.414734,307.753754 
	C883.342651,307.176361 883.270569,306.598969 883.043152,305.093079 
	C883.094604,292.720673 883.146057,280.348297 883.587769,267.673004 
	C883.454712,267.122925 883.321655,266.572845 883.037109,265.342010 
	C883.091125,264.883636 883.145142,264.425232 883.667664,263.566162 
	C883.455750,262.043579 883.243896,260.520966 882.838501,258.673737 
	C882.838501,258.673737 882.877808,258.297913 882.845215,257.446381 
	C877.725159,257.202820 872.605347,256.950134 867.482971,256.774658 
	C867.369568,256.770752 867.226135,257.646912 867.021667,257.993988 
	C867.021667,257.993988 867.114685,258.099670 866.432129,258.085907 
	C863.452148,259.144592 863.485046,260.470764 866.199524,262.687317 
	C866.100342,263.119720 866.001160,263.552094 865.111023,263.981018 
	C859.391113,263.917267 863.358887,259.607758 862.298096,256.538818 
	C856.293030,256.137909 850.296326,256.098633 844.302979,256.220978 
	C843.876709,256.229736 843.474670,257.425140 843.002441,257.998779 
	C843.002441,257.998779 843.067871,258.063873 842.369141,257.960907 
	C841.367737,257.794556 840.366394,257.628204 839.364990,257.461853 
	C839.095825,257.804718 838.826599,258.147614 838.557434,258.490479 
	C839.927917,260.001617 841.298401,261.512787 842.841187,263.666321 
	C842.791748,264.103943 842.742371,264.541595 842.109619,265.340271 
	C839.221375,266.872711 838.575317,264.961090 837.747131,262.754120 
	C836.697754,259.957581 835.311890,257.240570 831.136597,258.349182 
	C831.136597,258.349182 830.985291,258.111176 830.933594,257.433777 
	C829.952393,257.301788 828.971130,257.169800 827.308411,257.111816 
	C826.863281,257.017517 826.418091,256.923218 825.533569,256.367737 
	C824.365112,256.516785 823.196655,256.665833 821.247009,257.012878 
	C820.176270,257.043762 819.105530,257.074677 817.642761,256.544891 
	C816.448120,257.393616 815.253479,258.242310 814.040344,259.061768 
	C814.040344,259.061768 814.081482,259.082642 813.274231,259.026245 
	C810.012634,259.825714 806.751099,260.625153 803.374207,261.452850 
	C800.923889,258.864532 799.083618,254.544159 793.307739,257.113251 
	C792.861694,257.011810 792.415588,256.910370 791.444397,256.330658 
	C789.639404,256.492035 787.834351,256.653412 785.374390,257.010223 
	C784.922607,257.024902 784.470764,257.039581 783.578308,256.490936 
	C780.984741,255.515869 779.293945,256.067291 779.078857,259.116730 
	C779.078857,259.116730 779.121704,259.145477 778.366882,259.048553 
	C776.438599,260.473389 774.510315,261.898224 772.587830,263.331787 
	C771.150757,256.002838 771.150757,256.002533 763.240540,256.001099 
	C759.916321,256.000488 756.592102,255.994873 753.267883,256.003174 
	C747.078674,256.018616 746.038208,257.004944 746.011292,263.231171 
	C745.967468,273.369843 746.103882,283.511078 745.889832,293.645447 
	C745.856812,295.207764 744.360474,296.739227 743.214233,298.241486 
	C743.214233,298.241486 743.006714,297.985474 742.861084,297.225189 
	C741.329285,296.488708 739.797485,295.752228 737.997498,294.886810 
	C737.997498,284.233612 738.082092,273.499237 737.903992,262.769257 
	C737.877991,261.206360 736.597229,259.664337 735.863770,258.100769 
	C735.863770,258.100769 735.827087,258.106995 735.777161,257.297760 
	C734.305054,256.865601 732.838379,256.082977 731.359863,256.059784 
	C723.455383,255.935730 715.546631,255.916382 707.641968,256.035187 
	C703.351074,256.099670 699.063171,256.754211 694.777893,256.714142 
	C686.320435,256.635162 677.866638,256.182251 669.409119,256.095276 
	C668.288147,256.083771 667.154480,257.306671 666.012939,258.013031 
	C666.012939,258.013031 665.957703,258.026672 665.189941,258.103790 
	C664.518677,260.751556 663.153625,263.445068 663.324585,266.037292 
	C663.651550,270.994659 668.402588,269.568756 672.002441,270.609161 
	C672.002441,281.403290 671.998840,292.159149 672.003784,302.915009 
	C672.006897,309.677429 672.156250,309.699982 678.965088,310.082031 
	C680.710693,310.179932 682.400574,311.269958 684.116089,311.904205 
	C683.923401,312.642914 683.730713,313.381622 683.537964,314.120331 
	C679.834778,313.847015 676.132629,313.558105 672.428101,313.304810 
	C668.848083,313.060028 665.267273,312.710022 661.683655,312.666748 
	C657.965454,312.621857 654.136780,313.487000 650.554199,312.846985 
	C647.198853,312.247528 643.408569,315.907288 639.665405,311.903076 
	C642.322449,311.102600 644.242371,310.718597 645.992249,309.948334 
	C648.239868,308.959015 651.599121,308.894775 650.989075,304.998291 
	C650.989075,304.998291 650.967163,304.980713 651.732788,304.886993 
	C654.816956,301.064209 655.065308,287.719635 652.057800,284.368011 
	C651.808105,284.089722 651.113708,284.210449 650.217224,284.035614 
	C649.952881,283.857666 649.844849,283.611206 649.796997,282.717163 
	C649.502502,282.476532 649.208008,282.235901 648.528625,281.793152 
	C648.293457,281.613831 648.111084,281.391266 647.932373,280.369171 
	C646.535889,279.662506 645.139465,278.955872 643.937561,278.202393 
	C643.937561,278.202393 643.766724,278.306641 643.874268,277.524536 
	C643.758911,276.478943 643.643555,275.433319 643.550415,274.589233 
	C646.297119,274.023010 648.640747,273.539856 651.358154,272.709167 
	C651.597229,272.467194 651.836365,272.225220 652.608765,271.524384 
	C652.409790,270.017426 652.210754,268.510437 651.759094,266.389984 
	C651.774231,265.950531 651.789368,265.511108 652.422668,264.650543 
	C653.639526,260.827240 652.077942,259.208038 648.081543,259.420898 
	C648.081543,259.420898 647.929932,259.344696 647.923279,258.565430 
	C646.905273,255.753708 645.316956,255.024780 642.264404,257.275665 
	C641.837158,257.150635 641.409912,257.025604 640.455811,256.375458 
	C638.312012,256.528320 636.168152,256.681213 633.318665,257.047974 
	C632.591125,257.116333 631.863647,257.184692 630.705017,256.633118 
	C627.826965,256.396454 624.767578,255.901688 625.253723,260.801636 
M731.992676,784.257263 
	C730.341553,782.824829 728.690430,781.392395 727.018066,779.125061 
	C724.905334,767.731628 722.807251,756.335388 720.620544,744.956177 
	C720.542175,744.548523 719.588806,744.309021 719.007446,743.055542 
	C718.993896,724.375061 718.980408,705.694641 719.568848,686.445740 
	C719.368103,682.625854 719.167419,678.805908 718.958557,674.050476 
	C718.960571,662.369019 718.962585,650.687500 719.573242,638.429260 
	C719.714722,631.988892 719.972229,625.547913 719.942444,619.108337 
	C719.929932,616.399475 719.310364,613.693359 718.958618,610.050476 
	C718.955566,594.370117 718.952515,578.689819 719.562866,562.436157 
	C719.706421,561.629089 719.974609,560.822205 719.975342,560.015015 
	C719.998047,534.559448 720.009094,509.103912 719.951416,483.648499 
	C719.948669,482.429077 719.303406,481.211090 718.957947,479.057129 
	C718.956116,472.711090 718.954285,466.365021 719.510315,459.564789 
	C719.326416,458.037781 719.142578,456.510742 718.954468,454.184204 
	C718.916504,452.853180 718.878479,451.522156 718.939209,449.383728 
	C718.703125,445.467987 715.750854,445.056488 712.894226,445.046570 
	C691.645020,444.972900 670.395447,445.006744 649.145996,445.003784 
	C646.316345,445.003387 644.265991,445.927155 644.120850,449.783142 
	C644.094543,450.195465 644.068298,450.607758 643.341797,451.121033 
	C641.319336,452.759033 641.250549,454.099121 643.980347,455.846771 
	C643.973389,457.226776 643.966370,458.606750 643.231567,460.133026 
	C642.290527,464.331390 640.675842,468.513458 640.574036,472.732056 
	C640.437866,478.374969 641.907837,484.045898 641.911987,489.705322 
	C641.919250,499.729340 641.045105,509.754791 641.080383,519.777771 
	C641.129700,533.799866 641.832520,547.819092 641.940308,561.841919 
	C641.993713,568.788025 641.082764,575.739502 641.082825,582.688538 
	C641.082825,589.637634 641.946655,596.586121 641.962524,603.535950 
	C642.054199,643.822510 642.061218,684.109619 641.971924,724.396240 
	C641.947571,735.391235 643.115662,746.559509 638.233459,757.052673 
	C637.752686,758.086060 638.583435,759.654785 638.446777,760.936829 
	C637.949890,765.599915 637.300537,770.246826 636.735413,774.689636 
	C633.843506,774.280457 632.358337,774.070312 630.873108,773.860168 
	C631.360168,775.014648 632.121765,776.136047 632.265625,777.331787 
	C632.450867,778.871155 632.140930,780.470032 632.033447,782.021301 
	C632.033447,782.021301 632.041382,782.038147 631.348328,782.078613 
	C630.581665,783.069153 629.814941,784.059692 629.017090,785.004883 
	C629.017090,785.004883 629.038330,785.048279 628.261963,785.019226 
	C624.844727,787.354126 621.427490,789.689026 617.508484,792.066895 
	C617.342407,792.091064 617.176270,792.115234 616.545227,791.527100 
	C610.306458,792.539612 604.086792,793.700928 597.821167,794.503418 
	C594.557800,794.921448 590.705688,795.777466 588.032593,794.509949 
	C584.599365,792.881958 582.099426,788.773193 579.065796,794.689697 
	C578.958801,794.898376 578.068665,794.592712 577.658142,794.779114 
	C576.234436,795.425720 573.998596,795.890137 573.635620,796.956299 
	C572.658875,799.825134 572.127930,802.962036 572.069458,806.005005 
	C571.907898,814.415527 574.611511,816.996887 582.985535,816.996948 
	C638.984558,816.997437 694.983643,816.995789 750.982666,816.995361 
	C760.315857,816.995239 769.648987,816.989258 778.982178,817.005310 
	C781.033020,817.008850 783.194458,817.252930 783.694763,814.051392 
	C784.132507,814.041138 784.570190,814.030884 785.346069,814.477844 
	C785.901672,813.995605 786.457275,813.513367 787.230042,812.499573 
	C787.533325,812.011963 787.836609,811.524353 788.893433,810.819824 
	C790.284302,806.755005 791.679932,802.689819 787.786804,798.562927 
	C787.580627,798.295776 787.322693,798.095215 786.923767,797.311707 
	C786.247925,796.899536 785.572083,796.487427 783.972107,796.059509 
	C774.334534,795.964600 764.696960,795.869751 754.948547,795.237061 
	C754.300537,795.154419 753.652588,795.071777 752.233582,794.993591 
	C749.153076,793.997681 746.072510,793.001770 742.891479,791.314026 
	C740.658508,788.386169 737.973389,786.387024 733.484192,786.755676 
	C732.987671,786.171814 732.491089,785.587952 731.992676,784.257263 
M486.000000,945.000000 
	C486.000000,945.000000 485.996490,944.997192 486.796783,945.006714 
	C490.735931,945.258667 493.360168,943.420593 495.003632,939.920288 
	C495.003632,939.920288 494.919739,939.920898 495.708160,939.976990 
	C497.067993,939.547363 498.623505,939.405334 499.752808,938.636902 
	C503.989044,935.754456 508.499146,933.083984 512.096619,929.517151 
	C514.690918,926.944946 516.000793,923.077332 518.057861,919.378906 
	C518.233826,919.122986 518.471924,918.955322 519.547302,918.950989 
	C522.805054,917.171997 526.243286,915.535950 525.893372,910.975220 
	C525.893372,910.975220 525.886230,910.908875 526.629395,910.911499 
	C532.090271,909.655212 532.148376,904.591553 533.851135,900.148621 
	C534.116638,899.707825 534.382202,899.266968 535.468628,898.922852 
	C539.204285,895.681641 541.572449,891.730774 541.790466,886.113098 
	C541.838318,885.737793 541.886230,885.362549 542.665527,884.930664 
	C545.104736,880.763916 547.608032,876.631653 549.894958,872.382935 
	C550.155823,871.898254 549.203796,870.760803 548.835815,869.280334 
	C548.892334,868.853577 548.948853,868.426758 549.698303,867.894653 
	C552.868286,864.083862 556.922058,860.484863 552.821716,854.281189 
	C552.881165,853.854126 552.940552,853.427063 553.745178,852.913757 
	C557.193359,849.719604 558.919312,839.566101 556.798035,834.190247 
	C556.856934,833.458618 556.915833,832.726990 557.700500,831.885559 
	C558.800293,828.798645 560.282959,825.787170 560.909119,822.606995 
	C561.818176,817.989929 562.500061,813.251587 562.506042,808.564026 
	C562.509277,806.043274 560.900330,803.520508 559.951355,800.112732 
	C560.004089,797.744751 560.056763,795.376770 560.878418,792.806213 
	C561.251465,791.119263 561.949829,789.432434 561.950867,787.745300 
	C562.003967,702.319214 562.005310,616.893127 561.946228,531.467102 
	C561.944946,529.614990 561.177612,527.763367 560.765625,525.088562 
	C560.799683,523.716736 560.833801,522.344910 561.678040,520.900818 
	C562.438660,519.964111 563.763367,519.084839 563.865662,518.081055 
	C564.411438,512.723755 564.765137,507.338806 564.929993,501.955597 
	C564.980774,500.295563 564.228271,498.610962 563.836975,496.197876 
	C564.163513,494.783295 564.490051,493.368744 565.581787,491.923706 
	C566.941956,491.426483 568.302185,490.929260 568.299683,490.930176 
	C569.441833,487.714172 570.258301,485.640289 570.906067,483.515015 
	C571.366638,482.003998 571.599792,480.423737 572.226013,478.285828 
	C573.770874,477.104218 575.315674,475.922607 577.232727,475.317505 
	C579.645935,474.317352 582.127747,473.453827 584.455688,472.283997 
	C587.698608,470.654358 590.826294,468.795471 594.863586,466.941681 
	C596.875854,466.878906 598.888123,466.816101 601.443054,467.445404 
	C602.396362,467.629395 603.344177,467.934143 604.303894,467.978241 
	C611.688171,468.317230 619.073303,468.649933 626.461243,468.891083 
	C630.125977,469.010712 631.572998,467.143341 631.131653,462.885040 
	C631.329163,459.623993 631.526672,456.362915 632.352173,452.703583 
	C632.652771,447.069000 631.014221,445.137024 625.975586,446.251617 
	C604.345581,446.240417 582.715637,446.229218 560.539368,445.539062 
	C555.000366,445.759918 549.461426,445.980804 543.004456,446.210754 
	C538.695679,446.207275 534.386963,446.203827 529.553528,445.541748 
	C526.345459,445.755768 523.137390,445.969818 519.001404,446.197479 
	C486.027039,446.200562 453.052704,446.203644 419.684204,445.607910 
	C416.923950,445.188477 414.225220,444.845490 415.192413,449.774323 
	C415.112000,450.180786 415.031586,450.587280 414.257172,451.074341 
	C412.220276,453.191040 411.508453,459.567871 413.205444,461.392334 
	C413.589233,461.804962 414.420288,461.801575 415.181030,462.568542 
	C415.496765,463.290070 415.812531,464.011566 416.124329,465.570709 
	C417.680389,466.380035 419.207977,467.815308 420.797577,467.887604 
	C426.865234,468.163635 432.954620,468.081909 439.031860,467.949066 
	C440.714935,467.912323 442.384003,467.236389 444.796295,466.845398 
	C445.530762,466.899353 446.265228,466.953278 447.145935,467.752716 
	C452.659729,470.388733 458.146912,473.084290 463.723755,475.579285 
	C464.362122,475.864899 465.502136,475.029114 467.057739,474.827087 
	C468.399506,475.491760 469.741272,476.156403 471.107208,477.553070 
	C471.854004,477.977203 472.600769,478.401337 473.694336,478.795868 
	C473.694336,478.795868 474.025543,478.902832 474.014954,479.676270 
	C475.497803,483.077026 476.751892,486.606018 478.557404,489.825867 
	C479.498138,491.503540 481.410767,492.636292 483.002075,494.755096 
	C482.999390,495.503082 482.996704,496.251038 482.222412,497.017090 
	C481.591492,498.053162 480.370026,499.137390 480.449799,500.115509 
	C480.628510,502.307343 481.781708,504.421692 481.946930,506.612213 
	C482.265259,510.832611 482.025330,515.093079 482.285095,519.320435 
	C482.751587,526.912170 483.784851,534.487854 483.865265,542.077820 
	C483.916809,546.947632 482.044373,551.845520 482.153748,556.705566 
	C482.452087,569.957092 483.668579,583.189575 483.898438,596.440369 
	C484.079620,606.885193 483.202057,617.343933 483.039490,627.800293 
	C482.872437,638.540894 483.002930,649.286133 483.002258,660.029297 
	C483.001740,668.107544 482.889679,676.187500 483.021759,684.263611 
	C483.275818,699.797424 483.918549,715.328796 483.929199,730.861572 
	C483.939087,745.313843 483.156921,759.765503 483.071259,774.219482 
	C483.020203,782.837769 483.930939,791.461426 483.889435,800.080200 
	C483.825836,813.280518 483.278870,826.477966 483.000580,839.677979 
	C482.625641,857.461792 482.350464,875.247864 481.926483,893.030457 
	C481.865479,895.591003 481.510590,898.226990 480.774414,900.672363 
	C479.082764,906.291870 477.072113,911.815308 475.241577,917.713684 
	C475.241577,917.713684 475.145935,918.039795 474.374054,918.017700 
	C471.262878,920.732788 468.151672,923.447876 464.360229,926.519043 
	C462.577209,926.652039 460.794159,926.784973 458.929077,926.241821 
	C455.192688,923.164246 452.931458,917.959900 446.896881,917.455078 
	C446.872467,917.085938 446.848083,916.716736 446.892853,915.587219 
	C445.908295,914.395142 444.923737,913.203064 443.797852,911.392639 
	C443.464691,910.392822 443.131531,909.392944 442.925354,907.468689 
	C442.842590,903.701294 444.226440,899.048401 437.311829,898.634460 
	C436.479492,896.208984 435.647186,893.783447 434.926086,890.529358 
	C433.765961,887.674438 434.773651,883.324341 429.340088,882.736328 
	C429.154999,882.187744 428.969910,881.639099 429.383301,880.700562 
	C429.346252,876.586975 427.189758,873.908997 423.072845,871.902588 
	C422.005646,870.685242 420.938416,869.467896 419.954529,867.448669 
	C420.029114,864.369446 419.545013,862.035095 415.076691,864.167969 
	C414.372131,863.828918 413.667572,863.489868 412.918091,862.386414 
	C411.513336,861.642395 410.108551,860.898376 407.920898,860.173279 
	C406.621735,860.063782 405.322601,859.954285 404.002899,859.189819 
	C403.633179,858.578186 403.263458,857.966492 402.893768,857.354919 
	C402.240784,857.976501 401.587830,858.598083 400.146454,859.198669 
	C399.097229,859.131104 398.048004,859.063538 396.918121,858.272766 
	C394.480591,854.697815 392.492706,854.812317 390.159149,859.079285 
	C386.562622,859.447998 382.966095,859.816711 378.629761,860.127563 
	C378.097626,860.801208 377.565491,861.474854 376.421539,862.304932 
	C375.415527,862.606689 374.409546,862.908508 372.573975,863.085571 
	C369.592926,862.898560 368.820404,864.593933 368.700623,867.684143 
	C366.946381,868.863464 365.192139,870.042847 362.621796,871.092102 
	C356.718384,870.536987 360.764648,875.495789 359.072571,878.045532 
	C358.731445,879.700134 358.390320,881.354736 357.304657,883.076843 
	C353.460907,884.923218 352.860687,887.617432 355.203400,891.816040 
	C355.153320,892.547119 355.103210,893.278198 354.371857,894.090698 
	C352.393860,895.537964 352.741425,896.477356 355.067841,897.872498 
	C355.060028,899.917908 355.052246,901.963257 354.340271,904.067627 
	C351.797668,906.510864 354.157379,914.009216 358.194580,916.693176 
	C358.561890,918.075073 358.929169,919.456970 358.717163,921.199280 
	C360.187073,923.095215 361.657013,924.991089 363.099518,926.893860 
	C363.099518,926.893860 363.110413,926.906555 363.035522,927.697876 
	C363.816254,933.926941 367.082214,937.223816 374.706696,939.184875 
	C375.532898,940.001160 376.359100,940.817444 377.115448,942.415710 
	C378.201324,943.214111 379.287201,944.012512 380.922943,944.789062 
	C381.285095,944.843933 381.647247,944.898743 382.071381,945.672791 
	C383.530304,946.703979 384.989227,947.735168 387.081573,948.823792 
	C388.071350,949.156433 389.061157,949.489136 390.015564,950.668823 
	C390.469269,952.037292 390.923004,953.405701 391.376678,954.774170 
	C392.006134,953.992920 392.635590,953.211670 394.014526,952.545593 
	C395.022339,952.642151 396.030151,952.738770 397.080811,953.588501 
	C398.195801,956.297180 399.808441,956.934814 402.896149,954.903076 
	C407.932861,955.590820 412.969574,956.278564 418.103363,957.679016 
	C422.146057,958.780457 426.144562,960.522217 430.243774,960.805298 
	C434.444672,961.095398 438.763702,960.071045 442.983002,959.343445 
	C443.766876,959.208252 444.322906,957.751770 445.628387,956.824829 
	C446.060974,956.818481 446.493530,956.812073 447.360352,957.417053 
	C448.911743,957.000427 450.463135,956.583801 452.825745,956.036499 
	C454.192230,955.954956 455.558746,955.873413 457.220276,956.310486 
	C457.819122,955.925049 458.417969,955.539612 459.734192,955.006165 
	C460.462982,954.935974 461.191742,954.865784 462.312286,955.393127 
	C463.870544,954.569031 465.428802,953.744873 467.704315,952.846863 
	C468.664886,952.822083 469.625458,952.797241 471.342926,952.946228 
	C474.713257,954.534729 475.766388,952.814331 476.377289,949.283142 
	C477.911682,948.823059 479.446045,948.362976 481.060913,948.554321 
	C484.635071,950.044067 485.222443,947.389893 486.000000,945.000000 
M888.306519,270.120483 
	C893.419495,270.784790 890.244385,268.058960 890.627075,266.788391 
	C890.145569,266.599152 889.664062,266.409882 889.182495,266.220642 
	C888.820190,267.282990 888.457886,268.345337 888.306519,270.120483 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M542.778442,258.260803 
	C547.539856,257.508392 552.286865,256.627167 557.072815,256.095306 
	C558.249756,255.964539 559.553406,256.973114 560.367065,257.791382 
	C556.023560,258.124878 552.111694,258.124878 547.774475,258.124878 
	C547.774475,274.324799 547.737793,290.052765 547.877808,305.779114 
	C547.885132,306.603302 549.370361,308.094452 550.206055,308.122314 
	C556.273987,308.324860 562.351868,308.231171 568.610474,308.231171 
	C568.610474,304.649231 568.610474,301.751770 568.610474,298.447571 
	C566.578613,298.333008 564.754883,298.430969 563.042053,298.041321 
	C562.285889,297.869293 561.754761,296.707916 561.121460,295.995697 
	C570.004395,296.014984 570.025513,296.014923 569.994629,287.086517 
	C569.963196,278.005646 569.856567,268.924805 569.887634,259.844330 
	C569.889771,259.225372 570.770142,258.609406 571.569702,258.461060 
	C571.870239,274.038544 571.899536,289.147491 571.767273,304.255035 
	C571.741394,307.213470 572.575256,308.545410 575.720886,308.304779 
	C578.623901,308.082733 581.557312,308.257507 584.594849,308.257507 
	C584.594849,291.176422 584.594849,274.832214 584.594849,257.951019 
	C580.203064,257.951019 576.098633,257.951019 571.994263,257.951019 
	C574.430115,257.301453 576.846802,256.195129 579.305664,256.091492 
	C585.632935,255.824844 591.980286,255.933090 598.316589,256.052887 
	C599.833496,256.081604 601.338379,256.739716 602.898743,257.576965 
	C602.948669,258.045624 602.775085,258.218231 602.328735,258.148254 
	C597.983887,258.078308 594.085388,258.078308 590.182434,258.078308 
	C590.182434,271.406128 590.182434,284.134430 590.118286,297.238770 
	C590.195984,298.097870 590.337830,298.580872 590.382202,299.405060 
	C592.569641,306.966339 598.761292,310.279358 607.736328,309.084167 
	C614.367798,308.201080 618.519043,303.658783 618.713013,296.437439 
	C618.985413,286.295624 618.945251,276.145355 619.038757,265.998718 
	C620.783997,264.365387 622.529236,262.732086 624.701904,261.288452 
	C625.129272,261.478119 625.242065,261.963989 624.963379,262.252319 
	C620.037354,276.128082 623.810547,282.677460 635.833252,287.464386 
	C641.347778,291.525726 642.258728,295.513214 638.665222,301.262207 
	C637.082214,299.768158 635.075378,298.435333 634.910400,296.903992 
	C634.178711,290.114532 634.369751,290.079132 627.678406,290.174500 
	C626.236206,290.195099 624.796082,290.364716 623.437805,290.459900 
	C621.344849,303.688446 626.788635,309.959259 639.402893,308.924133 
	C643.525208,307.680176 647.252991,306.324860 650.980713,304.969543 
	C651.599121,308.894775 648.239868,308.959015 645.992249,309.948334 
	C644.242371,310.718597 642.322449,311.102600 639.665405,311.903076 
	C643.408569,315.907288 647.198853,312.247528 650.554199,312.846985 
	C654.136780,313.487000 657.965454,312.621857 661.683655,312.666748 
	C665.267273,312.710022 668.848083,313.060028 672.428101,313.304810 
	C676.132629,313.558105 679.834778,313.847015 683.537964,314.120331 
	C683.730713,313.381622 683.923401,312.642914 684.116089,311.904205 
	C682.400574,311.269958 680.710693,310.179932 678.965088,310.082031 
	C672.156250,309.699982 672.006897,309.677429 672.003784,302.915009 
	C671.998840,292.159149 672.002441,281.403290 672.002441,270.609161 
	C668.402588,269.568756 663.651550,270.994659 663.324585,266.037292 
	C663.153625,263.445068 664.518677,260.751556 665.557678,258.520538 
	C665.925415,261.861877 665.925415,264.786469 665.925415,267.610687 
	C673.831360,268.489716 673.841431,268.489685 673.853760,275.872986 
	C673.867371,284.031189 673.800110,292.189941 673.878113,300.347412 
	C673.962891,309.202942 672.558289,307.917603 681.924866,308.301239 
	C685.613525,308.452362 686.964111,307.290741 686.889343,303.543549 
	C686.703491,294.223602 686.808838,284.897369 686.848206,275.573853 
	C686.858459,273.154205 687.055786,270.735321 687.162781,268.412292 
	C688.237000,268.220825 688.743652,267.999084 689.206787,268.068359 
	C693.657043,268.733978 693.862366,266.082092 693.941284,262.662140 
	C694.027710,258.915863 692.687195,257.773193 688.998962,257.893402 
	C681.348999,258.142822 673.684937,257.961853 666.026672,257.957855 
	C667.154480,257.306671 668.288147,256.083771 669.409119,256.095276 
	C677.866638,256.182251 686.320435,256.635162 694.777893,256.714142 
	C699.063171,256.754211 703.351074,256.099670 707.641968,256.035187 
	C715.546631,255.916382 723.455383,255.935730 731.359863,256.059784 
	C732.838379,256.082977 734.305054,256.865601 735.352539,257.668518 
	C731.153442,258.039276 727.378845,258.039276 722.932129,258.039276 
	C722.932129,260.244934 722.931702,262.015808 722.932312,263.786713 
	C722.935913,274.590027 722.945984,285.393372 722.942017,296.196686 
	C722.937561,308.280182 722.932068,308.280151 734.842346,308.226379 
	C744.566345,308.182495 744.566345,308.182526 743.540894,298.284943 
	C744.360474,296.739227 745.856812,295.207764 745.889832,293.645447 
	C746.103882,283.511078 745.967468,273.369843 746.011292,263.231171 
	C746.038208,257.004944 747.078674,256.018616 753.267883,256.003174 
	C756.592102,255.994873 759.916321,256.000488 763.240540,256.001099 
	C771.150757,256.002533 771.150757,256.002838 772.584961,263.327423 
	C774.510315,261.898224 776.438599,260.473389 778.597534,259.405945 
	C777.277954,262.625854 774.536255,265.430756 774.394470,268.361420 
	C773.913147,278.310852 773.907288,288.335114 774.588501,298.271240 
	C775.065674,305.231903 779.623108,308.680481 786.601257,309.105011 
	C798.011902,309.799194 803.355774,304.657501 803.942749,293.050568 
	C804.173584,288.486908 802.908020,286.803864 798.144775,287.066772 
	C790.367126,287.496124 791.019653,286.208496 790.953979,294.446198 
	C790.947388,295.278564 791.174744,296.215424 790.869995,296.920715 
	C790.297485,298.245514 789.415283,299.436523 788.661865,300.683136 
	C788.086121,299.438263 787.034729,298.202118 787.011719,296.947144 
	C786.859375,288.625671 786.810120,280.297516 787.001526,271.977783 
	C787.053162,269.735931 788.052795,267.515900 788.617249,265.285828 
	C789.399963,267.532959 790.782166,269.760406 790.856567,272.030731 
	C791.112549,279.843536 790.278625,278.955383 797.866089,279.037476 
	C798.698486,279.046478 799.534973,278.986603 800.362671,279.049835 
	C802.872925,279.241608 803.875793,278.223480 803.960876,275.600159 
	C804.226074,267.424225 802.797424,260.291656 793.992126,257.047852 
	C799.083618,254.544159 800.923889,258.864532 803.374207,261.452850 
	C806.751099,260.625153 810.012634,259.825714 813.545044,259.370056 
	C810.283142,262.945129 808.852234,266.915588 808.970947,271.725647 
	C809.159241,279.356171 808.900574,286.996765 809.009521,294.630493 
	C809.144348,304.070526 813.930420,308.974670 822.905029,309.136688 
	C832.332886,309.306885 837.441284,304.648224 837.778503,295.134186 
	C838.031128,288.004272 837.825867,280.859161 837.901489,273.721619 
	C837.965881,267.644928 836.573181,262.230652 831.416504,258.383698 
	C835.311890,257.240570 836.697754,259.957581 837.747131,262.754120 
	C838.575317,264.961090 839.221375,266.872711 842.523315,265.616089 
	C842.965881,278.534027 842.928345,291.177124 843.113037,303.816956 
	C843.134521,305.282898 844.075684,307.687500 845.122314,308.029633 
	C851.254822,310.033997 853.937073,307.923767 854.023804,301.562805 
	C854.066833,298.402466 854.135559,295.242432 854.162170,292.081970 
	C854.185791,289.285004 854.167175,286.487671 854.167175,283.690521 
	C854.669373,283.698975 855.171509,283.707428 855.673706,283.715881 
	C856.484619,290.100830 857.230896,296.494995 858.136963,302.866394 
	C858.522705,305.579498 858.509766,308.403503 862.872559,308.336731 
	C866.969360,308.274078 867.390625,305.948273 867.767456,303.003326 
	C868.552612,296.867218 869.378296,290.736328 870.187256,284.603271 
	C870.730896,284.625641 871.274536,284.647980 871.818176,284.670349 
	C871.818176,289.029755 871.785461,293.389496 871.824646,297.748535 
	C871.924316,308.837982 871.937134,308.837860 882.953186,307.938629 
	C882.515808,311.815063 879.879395,310.887482 877.345581,310.700562 
	C875.901550,310.594086 874.402649,311.232788 873.138245,312.347870 
	C878.935181,314.873627 884.237488,315.403870 886.847473,308.976135 
	C887.892883,312.328491 888.729431,315.011078 889.977173,319.012207 
	C888.305969,326.994049 888.585022,336.560791 890.080322,346.281372 
	C891.271912,354.027496 891.695068,361.922913 891.985779,369.769592 
	C892.490112,383.383911 892.315002,397.029083 893.065369,410.625946 
	C893.392395,416.550415 895.244019,422.400269 896.515076,428.259735 
	C897.368591,432.194214 890.245300,439.390015 885.952515,438.808685 
	C885.788818,438.786499 885.629944,438.728668 885.468506,438.688629 
	C881.386169,437.675873 875.762451,437.949127 873.589172,435.343933 
	C870.470459,431.605377 869.473450,425.856781 868.332703,420.794586 
	C867.601685,417.550201 868.855408,413.856354 868.113708,410.617188 
	C867.184204,406.558319 863.776062,403.727875 864.364197,398.410828 
	C864.753845,394.888214 859.903137,390.837341 857.539429,386.937408 
	C856.321655,384.928192 855.417908,382.729126 854.360535,380.621765 
	C853.912415,379.728821 853.637268,378.311707 852.938660,378.048615 
	C844.567932,374.896332 842.196045,363.986847 832.114197,362.623383 
	C826.929382,361.922211 822.228455,357.910980 817.028259,357.003723 
	C801.441589,354.284424 785.963379,350.858307 769.843018,351.827057 
	C755.896057,352.665222 741.858826,351.982086 727.861572,352.009888 
	C721.541321,352.022461 719.995667,353.604095 719.993896,359.873566 
	C719.989075,376.537018 720.026367,393.200623 719.935059,409.863586 
	C719.925964,411.521362 719.148499,413.174957 718.265381,414.439636 
	C717.813110,394.717499 717.951538,375.384827 717.743774,356.055878 
	C717.690979,351.145905 719.459412,349.807739 724.173462,349.870514 
	C741.001953,350.094757 757.835510,349.917847 774.667114,349.934967 
	C788.277954,349.948822 801.833740,349.761475 815.302734,352.898468 
	C834.484863,357.366150 849.358765,367.395355 860.193176,383.893005 
	C868.841675,397.062164 871.397400,412.012573 873.997192,427.006805 
	C875.197083,433.927032 877.279602,435.447510 885.499451,434.959900 
	C891.506958,434.603607 891.091614,430.350311 890.910706,426.316528 
	C889.935669,404.575897 888.828796,382.841064 887.905151,361.098358 
	C887.396912,349.134003 887.196106,337.156860 886.786804,325.187866 
	C886.516968,317.295959 885.159790,316.022095 877.262024,316.021545 
	C766.607544,316.014099 655.953003,316.009827 545.298523,316.047729 
	C543.558167,316.048340 541.817993,316.588013 539.642517,316.873901 
	C522.360352,316.662628 505.510529,316.569519 488.668335,316.162354 
	C484.497559,316.061523 481.390778,316.906036 479.730591,320.973572 
	C477.223328,317.984985 478.208618,314.655945 482.040131,314.103363 
	C484.911041,313.689301 487.887115,314.001740 490.816345,313.994995 
	C492.696869,313.990692 494.577393,313.994202 496.719299,312.822510 
	C496.069885,312.255707 495.465546,311.267761 494.763550,311.192719 
	C491.099091,310.800903 489.455780,308.374054 489.147461,305.222839 
	C488.862091,302.306213 487.867371,298.847778 492.382233,297.463257 
	C493.315613,297.177063 493.907410,294.389771 493.929535,292.745392 
	C494.068298,282.450439 493.933441,272.151794 494.074463,261.856903 
	C494.095673,260.307861 495.087372,258.772156 495.926941,257.704163 
	C496.060303,269.966461 495.942780,281.755890 495.687225,293.542297 
	C495.645599,295.462219 495.622681,298.432098 494.510437,299.090210 
	C490.556091,301.429993 491.783844,304.560822 491.979004,307.671417 
	C504.613190,310.345001 508.617493,307.249390 508.732849,294.913635 
	C508.844788,282.945618 508.901031,270.977020 508.981750,259.008698 
	C510.128082,259.009888 511.274445,259.011078 513.015381,259.394653 
	C513.686584,271.908569 513.745483,284.040222 513.848022,296.171539 
	C513.892090,301.381836 515.751587,305.470367 520.817749,307.776794 
	C532.553284,313.119507 542.625854,306.928955 542.710388,294.161835 
	C542.789490,282.195190 542.760071,270.227844 542.778442,258.260803 
M709.822998,293.444214 
	C709.505249,289.683014 709.482483,286.314423 714.955444,286.935822 
	C718.420898,287.329285 717.762390,284.518738 717.852417,282.456360 
	C717.954712,280.111542 717.675049,277.759613 714.567139,278.165588 
	C708.916992,278.903656 710.089478,275.006714 709.830994,271.816589 
	C709.482605,267.516937 712.383484,267.830200 715.058167,268.019836 
	C718.398254,268.256714 718.991455,266.387146 718.808289,263.686066 
	C718.688660,261.922028 718.632080,260.153656 718.544556,258.331055 
	C711.146973,258.331055 704.272583,258.331055 697.348755,258.331055 
	C697.348755,275.062958 697.348755,291.412567 697.348755,308.190308 
	C703.560608,308.190308 709.371155,307.914368 715.141113,308.279846 
	C719.550659,308.559143 719.842285,306.385071 719.954163,302.895020 
	C720.075562,299.108582 718.788330,297.750763 715.094238,298.172974 
	C712.325623,298.489471 709.503601,298.512451 709.822998,293.444214 
M756.536255,257.934326 
	C754.873230,257.927795 753.040405,257.481262 751.595032,258.038910 
	C750.158630,258.593018 748.070007,260.140503 748.052124,261.280396 
	C747.824890,275.747101 747.838135,290.220276 748.084473,304.686737 
	C748.104675,305.870575 750.206970,307.933807 751.453735,308.022949 
	C756.749390,308.401489 762.103760,308.414551 767.397705,308.027985 
	C768.650513,307.936523 770.581848,305.899689 770.779053,304.550018 
	C771.594666,298.965820 770.885742,298.399445 765.093872,298.143707 
	C763.835693,298.088135 762.586792,297.823029 761.314331,297.651825 
	C761.314331,294.023743 761.314331,290.785156 761.314331,287.344849 
	C764.017822,287.096893 766.394958,286.878876 768.507324,286.685120 
	C768.507324,283.525604 768.507324,280.968109 768.507324,278.435944 
	C765.818054,278.154266 763.574158,277.919250 761.339417,277.685211 
	C761.339417,274.338440 761.339417,271.443817 761.339417,268.302643 
	C763.181885,268.209930 764.652466,268.026459 766.113159,268.085815 
	C768.913635,268.199677 769.863037,266.839844 769.986450,264.166473 
	C770.242920,258.614044 769.695984,257.934113 764.001526,257.924347 
	C761.839233,257.920654 759.676941,257.926178 756.536255,257.934326 
z"/>
<path fill="#96E1BF" opacity="1.000000" stroke="none" 
	d="
M373.403503,863.210266 
	C374.409546,862.908508 375.415527,862.606689 377.057770,862.183167 
	C378.252533,861.436035 378.811035,860.810669 379.369568,860.185364 
	C382.966095,859.816711 386.562622,859.447998 391.006592,859.081055 
	C393.568970,859.053833 395.283875,859.024902 396.998779,858.995972 
	C398.048004,859.063538 399.097229,859.131104 400.783508,859.429749 
	C402.288177,859.722168 403.155823,859.783447 404.023438,859.844788 
	C405.322601,859.954285 406.621735,860.063782 408.415588,860.495850 
	C410.261169,861.595825 411.612091,862.373291 412.963013,863.150757 
	C413.667572,863.489868 414.372131,863.828918 415.465271,864.524475 
	C417.192963,866.004150 418.532104,867.127319 419.871216,868.250488 
	C420.938416,869.467896 422.005646,870.685242 423.450989,872.389893 
	C425.481049,875.614929 427.132935,878.352722 428.784821,881.090515 
	C428.969910,881.639099 429.154999,882.187744 429.634277,883.268066 
	C431.557281,886.319214 433.186066,888.838562 434.814850,891.357971 
	C435.647186,893.783447 436.479492,896.208984 437.730286,899.182861 
	C439.698639,902.618530 441.248505,905.505859 442.798340,908.393127 
	C443.131531,909.392944 443.464691,910.392822 443.877014,912.054626 
	C444.912018,913.926941 445.867859,915.137268 446.823700,916.347595 
	C446.848083,916.716736 446.872467,917.085938 447.072479,918.043457 
	C451.169098,921.393860 455.090118,924.155945 459.011139,926.917969 
	C460.794159,926.784973 462.577209,926.652039 465.057739,926.295898 
	C468.885498,923.395081 472.015717,920.717468 475.145935,918.039795 
	C475.145935,918.039795 475.241577,917.713684 475.549744,917.421875 
	C477.134521,915.663452 479.162903,914.376770 479.564117,912.700684 
	C481.942474,902.764526 485.723480,892.749878 485.789398,882.740234 
	C486.303528,804.656189 486.652710,726.561523 485.877075,648.481812 
	C485.375488,597.988953 489.642242,547.378052 482.994019,496.999023 
	C482.996704,496.251038 482.999390,495.503082 482.961731,494.016022 
	C479.956085,488.485596 476.990814,483.694214 474.025543,478.902832 
	C474.025543,478.902832 473.694336,478.795868 473.399597,478.506714 
	C472.430939,477.752045 471.756989,477.286560 471.083038,476.821075 
	C469.741272,476.156403 468.399506,475.491760 466.603333,474.442078 
	C460.519562,469.659821 454.148041,467.278473 446.999695,467.007202 
	C446.265228,466.953278 445.530762,466.899353 444.147491,466.577332 
	C434.375214,465.783875 425.251740,465.258484 416.128265,464.733093 
	C415.812531,464.011566 415.496765,463.290070 415.127502,461.822052 
	C415.033020,457.714966 414.992096,454.354340 414.951172,450.993744 
	C415.031586,450.587280 415.112000,450.180786 415.593903,449.466461 
	C416.451294,449.299866 416.907196,449.441162 417.425018,449.989563 
	C415.794922,461.929840 415.924469,462.301575 427.218628,462.021912 
	C443.063263,461.629578 458.130310,464.121887 471.562714,473.063049 
	C477.576721,477.066193 483.857391,481.056732 485.272125,489.041992 
	C486.271301,494.681702 487.093903,500.352722 487.926453,506.469391 
	C488.173584,516.214539 488.786072,525.501282 488.748840,534.785400 
	C488.645050,560.654297 488.165588,586.521729 488.057983,612.390625 
	C488.015869,622.508728 488.546539,632.630127 488.862366,642.748596 
	C489.011597,647.530762 489.269226,652.309509 489.452026,657.524292 
	C489.436279,660.275757 489.446228,662.592773 489.243164,665.260132 
	C488.967041,667.268677 488.849915,668.926880 488.848877,670.585144 
	C488.824127,710.173218 488.853302,749.761292 488.785004,789.349304 
	C488.723297,825.108643 488.557495,860.867798 488.131226,896.808838 
	C486.591583,901.669739 485.358276,906.348938 484.039642,911.369873 
	C483.772186,912.142212 483.590027,912.572754 483.161316,913.149475 
	C482.265045,914.490295 481.615417,915.684998 480.904388,917.134277 
	C480.829834,917.563049 480.816620,917.737122 480.501190,918.063232 
	C479.609283,919.408020 479.019562,920.600830 478.248840,921.799805 
	C477.815613,921.939575 477.722565,922.145081 477.446716,922.484985 
	C475.983398,923.658203 474.862000,924.768921 473.418671,926.028442 
	C469.171234,927.456543 465.279633,929.687439 461.313538,929.828979 
	C451.179871,930.190369 445.497375,923.372131 441.394440,915.217957 
	C436.491364,905.473511 432.158020,895.442993 427.284302,885.683044 
	C423.259552,877.623108 418.316589,869.847778 410.309784,865.440063 
	C399.772430,859.639343 388.529816,860.379211 377.839966,865.477722 
	C374.443787,867.097534 371.807953,870.311584 368.484009,872.865601 
	C361.110016,878.669617 359.551636,886.775757 358.946838,895.091187 
	C357.835327,910.373596 363.157898,923.072998 374.041443,933.996033 
	C386.265625,946.264587 401.022491,952.140869 418.316711,953.947571 
	C424.220673,954.035034 429.656677,954.020752 435.544189,954.105957 
	C443.306915,953.239502 450.672089,952.562622 457.911743,951.212769 
	C463.350830,950.198669 468.628693,948.319946 474.281982,946.884277 
	C475.150391,946.448059 475.715179,945.953674 476.516907,945.390381 
	C476.753845,945.321594 477.210052,945.133423 477.535339,945.146362 
	C478.671173,944.601501 479.481689,944.043640 480.576416,943.389893 
	C481.239746,943.167480 481.618866,943.041016 482.354309,942.947266 
	C486.241272,940.444824 489.771942,937.909607 493.587402,935.311035 
	C494.248016,935.147156 494.623840,935.046692 495.350128,934.967407 
	C497.845093,933.048340 499.989594,931.108032 502.099060,929.110107 
	C502.064056,929.052429 502.169373,929.136597 502.507080,929.097656 
	C517.091370,917.586975 528.007996,903.422546 537.060242,887.609619 
	C547.204163,869.889526 550.239746,849.976990 554.524658,830.584290 
	C557.146973,818.716064 557.689453,806.205261 557.728333,793.978455 
	C558.013855,704.198120 557.794678,614.416138 558.147339,524.636230 
	C558.192810,513.055969 557.939575,501.287628 562.423096,489.916656 
	C565.176331,482.933929 568.797913,477.222778 574.752869,472.974274 
	C578.719238,470.144531 582.961975,467.702087 587.476379,465.072876 
	C588.912842,464.897797 589.953796,464.736145 591.379578,464.681091 
	C594.075134,464.022247 596.385986,463.256836 599.133545,462.573059 
	C600.729492,462.506683 601.888672,462.358704 603.471924,462.414612 
	C610.216858,462.651581 616.555847,462.965271 622.847839,462.555634 
	C624.870789,462.423920 628.310425,460.560394 628.466858,459.188721 
	C629.281067,452.046722 629.615295,449.093109 621.005005,449.121277 
	C573.410095,449.277039 525.814331,449.129211 478.218811,449.123871 
	C476.428131,449.123657 474.637421,449.519592 472.404602,449.621948 
	C453.820892,449.469269 435.679321,449.425598 417.387634,449.064209 
	C418.184479,447.899902 419.131409,447.053314 420.078339,446.206726 
	C453.052704,446.203644 486.027039,446.200562 519.778320,446.440918 
	C523.729614,446.522980 526.903870,446.361664 530.078186,446.200348 
	C534.386963,446.203827 538.695679,446.207275 543.794067,446.458771 
	C550.084290,446.543884 555.584961,446.380951 561.085632,446.218018 
	C582.715637,446.229218 604.345581,446.240417 626.657593,446.505676 
	C628.801086,448.873779 630.262634,450.987823 631.724243,453.101868 
	C631.526672,456.362915 631.329163,459.623993 630.563110,463.354156 
	C625.770691,464.170074 621.539856,464.449158 617.324646,464.880524 
	C611.843445,465.441467 606.374573,466.123444 600.900391,466.753326 
	C598.888123,466.816101 596.875854,466.878906 594.043274,466.952942 
	C587.768799,469.556458 582.314697,472.148712 576.860535,474.740997 
	C575.315674,475.922607 573.770874,477.104218 571.770142,478.736633 
	C569.148438,483.442993 566.982483,487.698578 564.816528,491.954163 
	C564.490051,493.368744 564.163513,494.783295 563.566711,496.859589 
	C562.486938,505.338562 561.677429,513.155823 560.867920,520.973083 
	C560.833801,522.344910 560.799683,523.716736 560.520630,525.836731 
	C560.166077,529.405457 559.961426,532.226074 559.960876,535.046631 
	C559.944946,613.704712 559.945557,692.362732 559.957947,771.020813 
	C559.959106,778.350159 560.056702,785.679443 560.109497,793.008789 
	C560.056763,795.376770 560.004089,797.744751 559.930969,801.013367 
	C559.643982,805.541870 559.458923,809.177917 559.092896,812.795715 
	C558.444824,819.201477 557.686768,825.596069 556.974792,831.995361 
	C556.915833,832.726990 556.856934,833.458618 556.548340,834.861572 
	C555.199097,841.355286 554.099548,847.177612 553.000000,853.000000 
	C552.940552,853.427063 552.881165,853.854126 552.565430,854.881104 
	C551.207947,859.654053 550.106628,863.827026 549.005371,868.000000 
	C548.948853,868.426758 548.892334,868.853577 548.574036,869.833374 
	C546.186218,875.253296 544.060181,880.120300 541.934143,884.987244 
	C541.886230,885.362549 541.838318,885.737793 541.510010,886.592041 
	C539.035645,890.989380 536.841675,894.907776 534.647705,898.826172 
	C534.382202,899.266968 534.116638,899.707825 533.510376,900.522156 
	C530.741821,904.233459 528.314026,907.571167 525.886230,910.908875 
	C525.886230,910.908875 525.893372,910.975220 525.593323,911.071167 
	C523.119568,913.736755 520.945923,916.306396 518.772217,918.875977 
	C518.471924,918.955322 518.233826,919.122986 517.677124,919.750671 
	C512.774536,924.162354 508.264191,928.215210 503.726868,932.237549 
	C500.811676,934.821838 497.856842,937.361328 494.919739,939.920898 
	C494.919739,939.920898 495.003632,939.920288 494.587280,939.900024 
	C491.446136,941.585510 488.721313,943.291321 485.996490,944.997192 
	C485.996490,944.997192 486.000000,945.000000 485.656830,945.028076 
	C483.869263,946.005127 482.424835,946.953979 480.980438,947.902893 
	C479.446045,948.362976 477.911682,948.823059 475.774231,949.563477 
	C473.642792,950.819946 472.114410,951.796204 470.586029,952.772461 
	C469.625458,952.797241 468.664886,952.822083 466.988953,952.860352 
	C464.822540,953.514404 463.371521,954.154968 461.920532,954.795532 
	C461.191742,954.865784 460.462982,954.935974 459.084869,955.029297 
	C457.932098,955.298950 457.428650,955.545410 456.925232,955.791870 
	C455.558746,955.873413 454.192230,955.954956 452.039520,956.033936 
	C449.810913,956.289490 448.368500,956.547607 446.926117,956.805725 
	C446.493530,956.812073 446.060974,956.818481 444.867859,956.841797 
	C435.406982,956.894592 426.706635,956.930420 418.006287,956.966309 
	C412.969574,956.278564 407.932861,955.590820 402.302673,954.546997 
	C400.152130,953.739136 398.595032,953.287231 397.037964,952.835388 
	C396.030151,952.738770 395.022339,952.642151 393.603210,952.166626 
	C392.144928,951.132385 391.097931,950.477112 390.050903,949.821777 
	C389.061157,949.489136 388.071350,949.156433 386.630737,948.461914 
	C384.789734,947.051208 383.399567,946.002380 382.009399,944.953552 
	C381.647247,944.898743 381.285095,944.843933 380.534271,944.479858 
	C379.158813,943.325073 378.172058,942.479431 377.185303,941.633789 
	C376.359100,940.817444 375.532898,940.001160 374.359741,938.712769 
	C370.378693,934.462585 366.744568,930.684570 363.110413,926.906555 
	C363.110413,926.906555 363.099518,926.893860 363.085327,926.542358 
	C361.812927,924.406860 360.554688,922.622864 359.296478,920.838867 
	C358.929169,919.456970 358.561890,918.075073 358.155426,915.950562 
	C357.092316,911.474792 356.068359,907.741760 355.044434,904.008667 
	C355.052246,901.963257 355.060028,899.917908 355.084839,897.063354 
	C355.085602,895.505920 355.069336,894.757629 355.053101,894.009277 
	C355.103210,893.278198 355.153320,892.547119 355.453308,891.194092 
	C356.485199,888.051208 357.267212,885.530273 358.049194,883.009338 
	C358.390320,881.354736 358.731445,879.700134 359.449677,877.568115 
	C361.030487,875.134521 362.234161,873.178345 363.437866,871.222168 
	C365.192139,870.042847 366.946381,868.863464 369.232605,867.372620 
	C370.977570,865.777466 372.190552,864.493835 373.403503,863.210266 
z"/>
<path fill="#62DFE8" opacity="1.000000" stroke="none" 
	d="
M540.077698,316.876129 
	C541.817993,316.588013 543.558167,316.048340 545.298523,316.047729 
	C655.953003,316.009827 766.607544,316.014099 877.262024,316.021545 
	C885.159790,316.022095 886.516968,317.295959 886.786804,325.187866 
	C887.196106,337.156860 887.396912,349.134003 887.905151,361.098358 
	C888.828796,382.841064 889.935669,404.575897 890.910706,426.316528 
	C891.091614,430.350311 891.506958,434.603607 885.499451,434.959900 
	C877.279602,435.447510 875.197083,433.927032 873.997192,427.006805 
	C871.397400,412.012573 868.841675,397.062164 860.193176,383.893005 
	C849.358765,367.395355 834.484863,357.366150 815.302734,352.898468 
	C801.833740,349.761475 788.277954,349.948822 774.667114,349.934967 
	C757.835510,349.917847 741.001953,350.094757 724.173462,349.870514 
	C719.459412,349.807739 717.690979,351.145905 717.743774,356.055878 
	C717.951538,375.384827 717.813110,394.717499 717.861633,414.511780 
	C717.895020,416.053741 717.869995,417.132721 717.839844,419.068756 
	C717.830505,421.925354 717.824646,423.924866 717.821960,425.924408 
	C717.812012,433.254150 716.152039,434.912109 708.644714,434.923798 
	C690.148376,434.952576 671.652039,434.971069 653.155701,434.954193 
	C646.435791,434.948059 645.052368,433.498474 645.047485,426.646118 
	C645.031006,403.317413 645.043152,379.988647 645.013123,356.659973 
	C645.005554,350.739075 644.275818,350.011139 638.391968,349.998383 
	C623.728271,349.966553 609.064331,350.015778 594.400757,349.965515 
	C585.919128,349.936432 577.437927,349.777649 569.088989,349.316040 
	C573.523071,348.578339 577.824646,348.201782 582.575928,347.878540 
	C586.828186,347.933289 590.633423,348.030945 594.432617,347.919586 
	C611.558228,347.417786 628.682068,346.856628 645.874023,346.669769 
	C646.916992,350.430695 648.697021,353.829315 648.737244,357.248352 
	C648.997620,379.387543 648.947144,401.531616 648.822021,423.673035 
	C648.799255,427.703430 649.267700,430.558807 654.239380,430.415802 
	C661.161377,430.216644 668.089539,430.231812 675.482483,430.173523 
	C686.780701,430.183411 697.614075,430.037109 708.440857,430.241547 
	C712.226013,430.312988 713.213745,428.990265 713.239014,425.334717 
	C713.400757,401.875214 713.798035,378.417450 714.091919,354.958740 
	C714.183533,347.647736 716.638733,345.182007 724.079468,345.176941 
	C745.074463,345.162720 766.105042,344.431396 787.051208,345.441406 
	C799.647766,346.048889 812.324524,348.551117 824.589539,351.676422 
	C845.177795,356.922607 858.239685,371.743561 868.358459,389.462280 
	C870.363159,392.972687 871.863586,396.943359 872.736694,400.891876 
	C874.550659,409.094666 875.772644,417.427734 877.269470,425.701508 
	C878.005676,429.771088 880.930969,430.353638 884.401123,430.143066 
	C888.278442,429.907776 887.216614,426.952209 887.093689,424.917358 
	C886.234314,410.691833 884.968384,396.487549 884.304749,382.254456 
	C883.631348,367.809326 883.650818,353.332703 883.058350,338.882507 
	C882.817078,332.997833 881.763000,327.146454 881.032104,320.880707 
	C813.215759,320.880707 746.102905,320.880707 678.522644,320.868439 
	C667.356445,320.852875 656.657715,320.849579 645.530762,320.845581 
	C625.390137,320.510193 605.677795,320.175507 585.524902,319.816010 
	C570.773499,319.176849 556.461914,318.576385 542.153687,317.906372 
	C541.447144,317.873291 540.769043,317.233459 540.077698,316.876129 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M582.126221,347.825226 
	C577.824646,348.201782 573.523071,348.578339 568.744019,349.382904 
	C567.801880,349.782898 567.337219,349.754852 566.575684,349.474731 
	C562.557251,349.562866 558.835693,349.903107 554.781250,350.255280 
	C554.005737,350.296997 553.562988,350.326752 552.719238,350.239746 
	C548.851685,351.152893 545.385010,352.182770 541.538269,353.358551 
	C536.404541,355.682404 531.416260,357.463898 526.941956,360.113373 
	C514.071106,367.734833 505.457886,379.265045 499.598328,392.690094 
	C494.613770,404.110535 491.487915,416.149628 490.717224,428.731903 
	C490.422211,433.547607 487.481720,435.078888 481.110413,434.735565 
	C476.751343,434.500671 474.860352,432.220856 475.044373,427.951874 
	C475.395355,419.809265 475.615326,411.660187 476.045105,403.521973 
	C476.973755,385.938049 478.100494,368.364166 478.943298,350.776428 
	C479.339325,342.511597 479.266144,334.224243 479.405579,325.947113 
	C479.576080,324.566406 479.746613,323.185699 479.823853,321.389282 
	C481.390778,316.906036 484.497559,316.061523 488.668335,316.162354 
	C505.510529,316.569519 522.360352,316.662628 539.642517,316.873901 
	C540.769043,317.233459 541.447144,317.873291 542.153687,317.906372 
	C556.461914,318.576385 570.773499,319.176849 585.534790,320.256897 
	C586.443909,329.688263 590.438965,339.179688 582.105591,346.854492 
	C581.948059,346.999573 582.112122,347.493835 582.126221,347.825226 
M529.692749,355.809906 
	C536.963989,351.498840 544.095581,346.826965 553.208008,347.885101 
	C554.287048,348.010406 555.471191,347.426697 556.577942,347.079895 
	C559.624512,346.125305 562.816650,340.202179 562.342773,337.398926 
	C561.807373,334.231384 561.558350,330.940521 561.766785,327.741180 
	C562.026123,323.759735 560.038696,321.040710 556.005005,320.996216 
	C550.340210,320.933685 544.674194,320.980225 538.070435,320.965973 
	C530.382996,320.963043 522.695557,320.960114 514.070007,320.931793 
	C505.262238,320.911133 496.454376,320.911377 487.646820,320.856842 
	C485.065521,320.840881 483.103760,321.240051 482.911102,324.501587 
	C482.374817,333.579620 481.361450,342.639038 481.105499,351.721802 
	C480.749878,364.343292 481.270782,376.994293 480.748749,389.604980 
	C480.257263,401.477020 478.834961,413.308533 477.979950,425.169220 
	C477.815369,427.451721 477.019592,430.439026 480.807129,431.019745 
	C484.497528,431.585480 486.400482,429.894989 487.075623,426.353149 
	C488.309052,419.882233 489.634644,413.428864 491.474335,406.613708 
	C491.936127,405.410522 492.397888,404.207336 492.967438,402.205231 
	C493.017822,400.863403 493.068237,399.521576 493.765594,397.952972 
	C494.485321,396.304443 495.205048,394.655945 496.521240,392.553741 
	C497.428253,390.122559 498.036133,387.521027 499.303833,385.295258 
	C502.048859,380.475555 505.131653,375.848236 508.758911,371.003357 
	C511.139465,368.660553 513.520020,366.317780 516.369263,363.744568 
	C516.572693,363.467987 516.776184,363.191406 517.601196,362.667999 
	C521.399597,360.379181 525.198059,358.090393 529.692749,355.809906 
z"/>
<path fill="#10CFED" opacity="1.000000" stroke="none" 
	d="
M882.968262,307.957123 
	C871.937134,308.837860 871.924316,308.837982 871.824646,297.748535 
	C871.785461,293.389496 871.818176,289.029755 871.818176,284.670349 
	C871.274536,284.647980 870.730896,284.625641 870.187256,284.603271 
	C869.378296,290.736328 868.552612,296.867218 867.767456,303.003326 
	C867.390625,305.948273 866.969360,308.274078 862.872559,308.336731 
	C858.509766,308.403503 858.522705,305.579498 858.136963,302.866394 
	C857.230896,296.494995 856.484619,290.100830 855.673706,283.715881 
	C855.171509,283.707428 854.669373,283.698975 854.167175,283.690521 
	C854.167175,286.487671 854.185791,289.285004 854.162170,292.081970 
	C854.135559,295.242432 854.066833,298.402466 854.023804,301.562805 
	C853.937073,307.923767 851.254822,310.033997 845.122314,308.029633 
	C844.075684,307.687500 843.134521,305.282898 843.113037,303.816956 
	C842.928345,291.177124 842.965881,278.534027 842.815063,265.435547 
	C842.742371,264.541595 842.791748,264.103943 842.871948,262.944641 
	C842.957764,260.836578 843.012817,259.450226 843.067871,258.063873 
	C843.067871,258.063873 843.002441,257.998779 843.492188,257.978363 
	C847.635803,257.952637 851.297974,258.099152 854.940918,257.897369 
	C858.293945,257.711609 860.021973,258.504303 860.362976,262.377075 
	C860.800171,267.342590 862.151733,272.227539 863.109314,277.147217 
	C863.502991,277.138947 863.896729,277.130707 864.290466,277.122437 
	C864.827637,272.743134 865.364868,268.363800 865.901978,263.984497 
	C866.001160,263.552094 866.100342,263.119720 866.381348,262.107117 
	C866.747070,260.384491 866.930847,259.242096 867.114685,258.099670 
	C867.114685,258.099670 867.021667,257.993988 867.515137,257.996887 
	C872.965027,258.099182 877.921448,258.198547 882.877808,258.297913 
	C882.877808,258.297913 882.838501,258.673737 882.877319,259.249512 
	C883.010498,261.205780 883.104858,262.586334 883.199219,263.966858 
	C883.145142,264.425232 883.091125,264.883636 883.017456,266.003540 
	C883.064392,267.102020 883.130920,267.538940 883.197510,267.975891 
	C883.146057,280.348297 883.094604,292.720673 883.001953,305.870667 
	C882.953491,307.091614 882.946228,307.535034 882.938965,307.978424 
	C882.938965,307.978424 882.983276,307.975616 882.968262,307.957123 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M553.120239,350.356506 
	C553.562988,350.326752 554.005737,350.296997 555.071411,350.515198 
	C559.420471,350.417694 563.146484,350.072235 566.872559,349.726807 
	C567.337219,349.754852 567.801880,349.782898 568.611572,349.744080 
	C577.437927,349.777649 585.919128,349.936432 594.400757,349.965515 
	C609.064331,350.015778 623.728271,349.966553 638.391968,349.998383 
	C644.275818,350.011139 645.005554,350.739075 645.013123,356.659973 
	C645.043152,379.988647 645.031006,403.317413 645.047485,426.646118 
	C645.052368,433.498474 646.435791,434.948059 653.155701,434.954193 
	C671.652039,434.971069 690.148376,434.952576 708.644714,434.923798 
	C716.152039,434.912109 717.812012,433.254150 717.821960,425.924408 
	C717.824646,423.924866 717.830505,421.925354 717.893188,419.470123 
	C718.631042,420.120209 719.845459,421.203979 719.892029,422.335785 
	C720.046936,426.102448 719.934082,429.900391 719.586243,433.655090 
	C719.482117,434.778259 718.472778,436.468750 717.533691,436.750244 
	C713.551697,437.943726 709.442993,439.445984 705.375061,439.462341 
	C697.989807,439.492096 690.607605,438.385773 683.210693,438.054688 
	C677.479065,437.798157 671.726929,437.999878 665.983887,437.998962 
	C660.987854,437.998169 655.991699,438.013977 650.995728,437.995361 
	C643.679382,437.968109 639.452759,431.459595 640.627747,424.213928 
	C641.810547,416.919373 641.700500,409.383270 641.781616,401.948944 
	C641.929810,388.372314 641.554871,374.786102 641.918030,361.217804 
	C642.049927,356.291351 639.333435,352.049347 634.175537,352.030701 
	C615.523804,351.963226 596.860046,351.615448 578.224731,352.174042 
	C568.933777,352.452515 559.672424,354.322113 550.441895,355.756531 
	C545.809204,356.476440 540.706238,356.866577 536.839294,359.143616 
	C531.874695,362.066986 525.383667,362.896698 522.236877,368.911896 
	C521.307434,370.688538 517.839355,371.101471 516.827332,372.876434 
	C511.272858,382.618378 501.316711,389.752808 499.793884,401.907867 
	C499.111908,407.351257 498.888519,413.250397 496.529236,417.981293 
	C493.781067,423.491974 494.689484,429.127197 493.264160,434.478027 
	C492.745392,436.425751 488.886108,437.736786 486.322266,438.771759 
	C485.173767,439.235382 483.473236,438.124786 482.004486,438.035065 
	C471.357086,437.384613 470.877533,437.024323 471.041443,426.280853 
	C471.155121,418.827667 471.520905,411.371735 472.011047,403.932648 
	C472.528229,396.083130 473.470215,388.261169 473.962799,380.410583 
	C474.471466,372.303558 474.548126,364.169891 475.023224,356.060150 
	C475.559814,346.900940 476.287292,337.751831 477.064240,328.609558 
	C477.141205,327.704010 478.140594,326.876831 479.058716,325.980225 
	C479.266144,334.224243 479.339325,342.511597 478.943298,350.776428 
	C478.100494,368.364166 476.973755,385.938049 476.045105,403.521973 
	C475.615326,411.660187 475.395355,419.809265 475.044373,427.951874 
	C474.860352,432.220856 476.751343,434.500671 481.110413,434.735565 
	C487.481720,435.078888 490.422211,433.547607 490.717224,428.731903 
	C491.487915,416.149628 494.613770,404.110535 499.598328,392.690094 
	C505.457886,379.265045 514.071106,367.734833 526.941956,360.113373 
	C531.416260,357.463898 536.404541,355.682404 541.889221,353.579071 
	C546.120300,352.554626 549.620239,351.455566 553.120239,350.356506 
z"/>
<path fill="#10A723" opacity="1.000000" stroke="none" 
	d="
M542.804321,258.254517 
	C542.760071,270.227844 542.789490,282.195190 542.710388,294.161835 
	C542.625854,306.928955 532.553284,313.119507 520.817749,307.776794 
	C515.751587,305.470367 513.892090,301.381836 513.848022,296.171539 
	C513.745483,284.040222 513.686584,271.908569 513.445190,259.401184 
	C513.524170,258.845215 513.709473,258.617584 514.381470,258.145233 
	C518.881226,258.050415 522.835999,258.152802 526.790833,258.255188 
	C526.790833,258.255188 526.844482,258.156616 526.761963,258.618561 
	C526.764648,272.911072 526.849854,286.741638 526.935120,300.572205 
	C527.930786,300.564728 528.926514,300.557281 529.922180,300.549805 
	C529.922180,286.364777 529.922180,272.179718 529.922180,257.994690 
	C529.922180,257.994690 529.986877,258.009491 530.451416,257.979980 
	C534.873413,258.052460 538.830811,258.154449 542.788208,258.256470 
	C542.788208,258.256470 542.830261,258.248230 542.804321,258.254517 
z"/>
<path fill="#10CFED" opacity="1.000000" stroke="none" 
	d="
M831.276550,258.366455 
	C836.573181,262.230652 837.965881,267.644928 837.901489,273.721619 
	C837.825867,280.859161 838.031128,288.004272 837.778503,295.134186 
	C837.441284,304.648224 832.332886,309.306885 822.905029,309.136688 
	C813.930420,308.974670 809.144348,304.070526 809.009521,294.630493 
	C808.900574,286.996765 809.159241,279.356171 808.970947,271.725647 
	C808.852234,266.915588 810.283142,262.945129 813.948730,259.398254 
	C814.081482,259.082642 814.040344,259.061768 814.377808,259.069672 
	C815.821716,258.420258 816.928284,257.762939 818.034790,257.105591 
	C819.105530,257.074677 820.176270,257.043762 822.027222,256.993134 
	C823.862610,256.925232 824.917786,256.877075 825.972961,256.828918 
	C826.418091,256.923218 826.863281,257.017517 827.875488,257.313904 
	C829.290161,257.714355 830.137695,257.912750 830.985291,258.111176 
	C830.985291,258.111176 831.136597,258.349182 831.276550,258.366455 
M821.754028,292.429077 
	C822.006409,295.269257 822.258728,298.109406 822.511108,300.949585 
	C823.099792,300.893738 823.688416,300.837860 824.277100,300.782013 
	C824.277100,288.928436 824.277100,277.074860 824.277100,265.221283 
	C823.572937,265.193726 822.868835,265.166168 822.164673,265.138641 
	C821.325806,273.862854 822.009888,282.685059 821.754028,292.429077 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M482.608215,497.008057 
	C489.642242,547.378052 485.375488,597.988953 485.877075,648.481812 
	C486.652710,726.561523 486.303528,804.656189 485.789398,882.740234 
	C485.723480,892.749878 481.942474,902.764526 479.564117,912.700684 
	C479.162903,914.376770 477.134521,915.663452 475.524414,917.253906 
	C477.072113,911.815308 479.082764,906.291870 480.774414,900.672363 
	C481.510590,898.226990 481.865479,895.591003 481.926483,893.030457 
	C482.350464,875.247864 482.625641,857.461792 483.000580,839.677979 
	C483.278870,826.477966 483.825836,813.280518 483.889435,800.080200 
	C483.930939,791.461426 483.020203,782.837769 483.071259,774.219482 
	C483.156921,759.765503 483.939087,745.313843 483.929199,730.861572 
	C483.918549,715.328796 483.275818,699.797424 483.021759,684.263611 
	C482.889679,676.187500 483.001740,668.107544 483.002258,660.029297 
	C483.002930,649.286133 482.872437,638.540894 483.039490,627.800293 
	C483.202057,617.343933 484.079620,606.885193 483.898438,596.440369 
	C483.668579,583.189575 482.452087,569.957092 482.153748,556.705566 
	C482.044373,551.845520 483.916809,546.947632 483.865265,542.077820 
	C483.784851,534.487854 482.751587,526.912170 482.285095,519.320435 
	C482.025330,515.093079 482.265259,510.832611 481.946930,506.612213 
	C481.781708,504.421692 480.628510,502.307343 480.449799,500.115509 
	C480.370026,499.137390 481.591492,498.053162 482.608215,497.008057 
z"/>
<path fill="#10CFED" opacity="1.000000" stroke="none" 
	d="
M793.649902,257.080566 
	C802.797424,260.291656 804.226074,267.424225 803.960876,275.600159 
	C803.875793,278.223480 802.872925,279.241608 800.362671,279.049835 
	C799.534973,278.986603 798.698486,279.046478 797.866089,279.037476 
	C790.278625,278.955383 791.112549,279.843536 790.856567,272.030731 
	C790.782166,269.760406 789.399963,267.532959 788.617249,265.285828 
	C788.052795,267.515900 787.053162,269.735931 787.001526,271.977783 
	C786.810120,280.297516 786.859375,288.625671 787.011719,296.947144 
	C787.034729,298.202118 788.086121,299.438263 788.661865,300.683136 
	C789.415283,299.436523 790.297485,298.245514 790.869995,296.920715 
	C791.174744,296.215424 790.947388,295.278564 790.953979,294.446198 
	C791.019653,286.208496 790.367126,287.496124 798.144775,287.066772 
	C802.908020,286.803864 804.173584,288.486908 803.942749,293.050568 
	C803.355774,304.657501 798.011902,309.799194 786.601257,309.105011 
	C779.623108,308.680481 775.065674,305.231903 774.588501,298.271240 
	C773.907288,288.335114 773.913147,278.310852 774.394470,268.361420 
	C774.536255,265.430756 777.277954,262.625854 778.974915,259.454407 
	C779.121704,259.145477 779.078857,259.116730 779.431030,259.118439 
	C781.195190,258.431519 782.607056,257.742920 784.018982,257.054291 
	C784.470764,257.039581 784.922607,257.024902 786.119507,257.000488 
	C788.566284,256.930115 790.267883,256.869507 791.969543,256.808899 
	C792.415588,256.910370 792.861694,257.011810 793.649902,257.080566 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M783.046509,814.156616 
	C783.194458,817.252930 781.033020,817.008850 778.982178,817.005310 
	C769.648987,816.989258 760.315857,816.995239 750.982666,816.995361 
	C694.983643,816.995789 638.984558,816.997437 582.985535,816.996948 
	C574.611511,816.996887 571.907898,814.415527 572.069458,806.005005 
	C572.127930,802.962036 572.658875,799.825134 573.635620,796.956299 
	C573.998596,795.890137 576.234436,795.425720 577.658142,794.779114 
	C578.068665,794.592712 578.958801,794.898376 579.065796,794.689697 
	C582.099426,788.773193 584.599365,792.881958 588.032593,794.509949 
	C590.705688,795.777466 594.557800,794.921448 597.821167,794.503418 
	C604.086792,793.700928 610.306458,792.539612 616.634644,792.101135 
	C615.781372,793.682861 614.838745,794.690613 613.584351,795.779175 
	C612.844727,795.890930 612.416870,795.921814 611.805786,795.703369 
	C610.705200,795.855042 609.787903,796.256165 608.487915,796.737488 
	C607.063477,796.848511 606.021667,796.879272 604.768738,796.655273 
	C603.325867,796.803101 602.094116,797.205688 600.411133,797.693848 
	C593.963196,797.819092 587.961365,797.734741 581.973022,797.979919 
	C580.334656,798.047058 577.526489,798.846802 577.335205,799.750549 
	C576.609863,803.178223 576.034973,806.966614 576.875366,810.242615 
	C577.236389,811.650085 581.178711,812.853638 583.526550,812.915588 
	C595.013794,813.218872 606.512146,813.106201 618.311707,813.423828 
	C620.601257,813.817322 622.585449,814.021729 624.569946,814.023865 
	C677.395447,814.080811 730.221008,814.116943 783.046509,814.156616 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M632.044250,782.043274 
	C632.140930,780.470032 632.450867,778.871155 632.265625,777.331787 
	C632.121765,776.136047 631.360168,775.014648 630.873108,773.860168 
	C632.358337,774.070312 633.843506,774.280457 636.735413,774.689636 
	C637.300537,770.246826 637.949890,765.599915 638.446777,760.936829 
	C638.583435,759.654785 637.752686,758.086060 638.233459,757.052673 
	C643.115662,746.559509 641.947571,735.391235 641.971924,724.396240 
	C642.061218,684.109619 642.054199,643.822510 641.962524,603.535950 
	C641.946655,596.586121 641.082825,589.637634 641.082825,582.688538 
	C641.082764,575.739502 641.993713,568.788025 641.940308,561.841919 
	C641.832520,547.819092 641.129700,533.799866 641.080383,519.777771 
	C641.045105,509.754791 641.919250,499.729340 641.911987,489.705322 
	C641.907837,484.045898 640.437866,478.374969 640.574036,472.732056 
	C640.675842,468.513458 642.290527,464.331390 643.622437,460.529419 
	C644.008057,553.707947 643.997681,646.490112 644.033020,739.272217 
	C644.033752,741.188416 644.563293,743.104370 644.880310,745.335815 
	C644.891418,746.073730 644.868286,746.496155 644.473633,747.036499 
	C643.557129,750.469238 643.197388,753.827637 642.430176,757.090332 
	C640.644653,764.683594 638.662781,772.230652 636.439331,779.888062 
	C634.760132,780.667603 633.402222,781.355408 632.044250,782.043274 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M644.846130,745.020447 
	C644.563293,743.104370 644.033752,741.188416 644.033020,739.272217 
	C643.997681,646.490112 644.008057,553.707947 643.986328,460.456299 
	C643.966370,458.606750 643.973389,457.226776 643.988403,455.033203 
	C644.011597,453.153137 644.026794,452.086609 644.041992,451.020081 
	C644.068298,450.607758 644.094543,450.195465 644.492188,449.374176 
	C647.345398,448.297119 649.822693,447.070770 652.310059,447.049744 
	C671.226746,446.889771 690.146057,447.054382 709.062744,446.893066 
	C712.861633,446.860657 716.148560,447.396759 718.840576,450.191132 
	C718.878479,451.522156 718.916504,452.853180 718.665833,454.600922 
	C717.989197,455.044739 717.601135,455.071869 717.082703,454.737518 
	C715.991760,450.337494 713.520874,448.895569 709.322510,448.933319 
	C690.831909,449.099609 672.338745,448.983276 653.846558,448.978943 
	C646.449341,448.977203 645.975281,449.494781 645.974182,456.994598 
	C645.960388,551.121826 645.945801,645.249084 645.875732,739.376221 
	C645.874329,741.257935 645.204224,743.139038 644.846130,745.020447 
z"/>
<path fill="#10CFED" opacity="1.000000" stroke="none" 
	d="
M743.377563,298.263214 
	C744.566345,308.182526 744.566345,308.182495 734.842346,308.226379 
	C722.932068,308.280151 722.937561,308.280182 722.942017,296.196686 
	C722.945984,285.393372 722.935913,274.590027 722.932312,263.786713 
	C722.931702,262.015808 722.932129,260.244934 722.932129,258.039276 
	C727.378845,258.039276 731.153442,258.039276 735.377563,258.073120 
	C735.827087,258.106995 735.863770,258.100769 735.859253,258.565216 
	C735.852173,269.850006 735.843323,280.670319 735.849548,291.490662 
	C735.853455,298.272522 735.888733,298.295349 743.006714,297.985474 
	C743.006714,297.985474 743.214233,298.241486 743.377563,298.263214 
z"/>
<path fill="#10A723" opacity="1.000000" stroke="none" 
	d="
M561.019348,295.536743 
	C561.754761,296.707916 562.285889,297.869293 563.042053,298.041321 
	C564.754883,298.430969 566.578613,298.333008 568.610474,298.447571 
	C568.610474,301.751770 568.610474,304.649231 568.610474,308.231171 
	C562.351868,308.231171 556.273987,308.324860 550.206055,308.122314 
	C549.370361,308.094452 547.885132,306.603302 547.877808,305.779114 
	C547.737793,290.052765 547.774475,274.324799 547.774475,258.124878 
	C552.111694,258.124878 556.023560,258.124878 560.384216,258.206787 
	C560.861084,270.551727 560.889160,282.814789 561.019348,295.536743 
z"/>
<path fill="#02B358" opacity="1.000000" stroke="none" 
	d="
M618.936523,265.578308 
	C618.945251,276.145355 618.985413,286.295624 618.713013,296.437439 
	C618.519043,303.658783 614.367798,308.201080 607.736328,309.084167 
	C598.761292,310.279358 592.569641,306.966339 590.690918,299.474121 
	C594.691345,305.909668 598.898865,308.033203 605.295776,305.827240 
	C604.417114,304.553436 603.539001,303.466858 602.862732,302.266571 
	C600.417114,297.925995 599.703369,293.504944 602.622864,289.449493 
	C602.935303,293.456268 603.134277,297.021912 603.333252,300.587524 
	C604.199341,300.553894 605.065430,300.520264 605.931519,300.486603 
	C605.931519,286.331146 605.931519,272.175659 605.931519,258.020172 
	C606.110840,257.942444 606.290100,257.864746 607.159302,257.821075 
	C611.505005,258.056000 615.160645,258.256866 618.816345,258.457764 
	C618.816345,258.457764 618.729614,258.771576 618.790100,259.144104 
	C618.918640,259.676636 618.986633,259.836670 619.054688,259.996704 
	C618.981262,261.717102 618.907776,263.437500 618.936523,265.578308 
z"/>
<path fill="#10A723" opacity="1.000000" stroke="none" 
	d="
M508.858276,258.889343 
	C508.901031,270.977020 508.844788,282.945618 508.732849,294.913635 
	C508.617493,307.249390 504.613190,310.345001 491.979004,307.671417 
	C491.783844,304.560822 490.556091,301.429993 494.510437,299.090210 
	C495.622681,298.432098 495.645599,295.462219 495.687225,293.542297 
	C495.942780,281.755890 496.060303,269.966461 496.352417,257.742432 
	C496.481140,257.306793 496.625427,257.500122 497.098236,257.687744 
	C501.322052,258.063019 505.073090,258.250702 508.824097,258.438354 
	C508.824097,258.438354 508.734772,258.769989 508.858276,258.889343 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M560.493958,792.907471 
	C560.056702,785.679443 559.959106,778.350159 559.957947,771.020813 
	C559.945557,692.362732 559.944946,613.704712 559.960876,535.046631 
	C559.961426,532.226074 560.166077,529.405457 560.521606,526.248230 
	C561.177612,527.763367 561.944946,529.614990 561.946228,531.467102 
	C562.005310,616.893127 562.003967,702.319214 561.950867,787.745300 
	C561.949829,789.432434 561.251465,791.119263 560.493958,792.907471 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M783.370605,814.104004 
	C730.221008,814.116943 677.395447,814.080811 624.569946,814.023865 
	C622.585449,814.021729 620.601257,813.817322 618.307617,813.067627 
	C617.993347,812.191162 617.988220,811.954346 618.378418,811.775574 
	C620.821533,811.696472 622.869385,811.559265 625.273438,811.644043 
	C625.955200,811.931763 626.280823,812.054932 626.606445,812.055054 
	C678.041870,812.072449 729.477417,812.049011 780.912659,812.143188 
	C785.647278,812.151855 785.846924,809.080261 785.923950,805.874268 
	C786.007080,802.412537 785.681824,799.082336 780.868225,798.940063 
	C773.073120,798.709656 765.274963,798.436707 757.492920,797.946594 
	C750.062500,797.478638 742.972290,795.743958 736.956543,791.047363 
	C737.037170,790.967102 737.201233,790.809326 737.573669,790.850891 
	C739.628052,791.263611 741.310059,791.634766 742.992004,792.005920 
	C746.072510,793.001770 749.153076,793.997681 752.790771,795.237671 
	C753.918396,795.579468 754.488892,795.677124 755.059387,795.774841 
	C764.696960,795.869751 774.334534,795.964600 784.505859,796.378418 
	C785.697327,797.118652 786.355164,797.539978 787.013000,797.961304 
	C787.322693,798.095215 787.580627,798.295776 787.894653,799.190613 
	C788.048279,803.557800 788.094055,807.297241 788.139893,811.036743 
	C787.836609,811.524353 787.533325,812.011963 786.819702,812.770996 
	C785.942200,813.368530 785.475098,813.694641 785.007935,814.020630 
	C784.570190,814.030884 784.132507,814.041138 783.370605,814.104004 
z"/>
<path fill="#01BA79" opacity="1.000000" stroke="none" 
	d="
M650.984863,304.983917 
	C647.252991,306.324860 643.525208,307.680176 639.397827,308.559387 
	C639.028503,305.832855 639.058899,303.582458 639.089233,301.332062 
	C642.258728,295.513214 641.347778,291.525726 636.219543,287.416077 
	C642.696838,286.917908 639.900024,281.055634 642.289429,278.246521 
	C642.979370,278.237579 643.373047,278.272095 643.766724,278.306641 
	C643.766724,278.306641 643.937561,278.202393 643.959595,278.537506 
	C645.314880,279.623566 646.648193,280.374481 647.981445,281.125397 
	C648.111084,281.391266 648.293457,281.613831 648.742920,282.197327 
	C649.269104,282.833069 649.581055,283.064667 649.893005,283.296265 
	C649.844849,283.611206 649.952881,283.857666 650.464844,284.478058 
	C653.877686,291.568115 652.971619,298.267456 650.967163,304.980713 
	C650.967163,304.980713 650.989075,304.998291 650.984863,304.983917 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M718.957336,479.992432 
	C719.303406,481.211090 719.948669,482.429077 719.951416,483.648499 
	C720.009094,509.103912 719.998047,534.559448 719.975342,560.015015 
	C719.974609,560.822205 719.706421,561.629089 718.963135,562.708618 
	C717.972046,562.955444 717.580688,562.929810 717.145630,562.446228 
	C717.136902,534.692383 717.171997,507.396515 717.499573,480.090576 
	C718.180481,480.051117 718.568909,480.021759 718.957336,479.992432 
z"/>
<path fill="#01BA79" opacity="1.000000" stroke="none" 
	d="
M651.804443,265.071655 
	C651.789368,265.511108 651.774231,265.950531 651.756714,267.099304 
	C651.861328,269.200165 651.968384,270.591705 652.075439,271.983246 
	C651.836365,272.225220 651.597229,272.467194 650.712646,272.843689 
	C646.774536,272.978241 643.481995,272.978241 640.535217,272.978241 
	C638.928650,269.878174 641.792786,264.999847 636.042725,264.680847 
	C637.090454,262.576355 638.103271,260.834625 639.709534,258.072357 
	C636.185181,258.072357 633.782043,258.072357 631.316162,257.868164 
	C631.253357,257.663940 631.136108,257.253052 631.136108,257.253052 
	C631.863647,257.184692 632.591125,257.116333 634.099121,257.028625 
	C636.914001,256.973053 638.948303,256.936829 640.982666,256.900574 
	C641.409912,257.025604 641.837158,257.150635 642.826477,257.493591 
	C644.902344,258.255890 646.416138,258.800293 647.929932,259.344696 
	C647.929932,259.344696 648.081543,259.420898 648.324951,259.706787 
	C649.647095,261.685669 650.725769,263.378662 651.804443,265.071655 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M718.966919,687.014160 
	C718.980408,705.694641 718.993896,724.375061 718.998718,743.976013 
	C719.307495,748.325256 719.655701,751.751526 719.934937,755.183411 
	C720.309509,759.787598 720.630798,764.396240 720.691101,768.757446 
	C719.608215,765.313599 718.171875,762.127197 718.111511,758.914917 
	C717.660828,734.911621 717.482300,710.903198 717.510620,686.907227 
	C718.190857,686.950073 718.578918,686.982117 718.966919,687.014160 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M718.889893,449.787415 
	C716.148560,447.396759 712.861633,446.860657 709.062744,446.893066 
	C690.146057,447.054382 671.226746,446.889771 652.310059,447.049744 
	C649.822693,447.070770 647.345398,448.297119 644.493530,449.064270 
	C644.265991,445.927155 646.316345,445.003387 649.145996,445.003784 
	C670.395447,445.006744 691.645020,444.972900 712.894226,445.046570 
	C715.750854,445.056488 718.703125,445.467987 718.889893,449.787415 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M529.658203,258.081696 
	C529.922180,272.179718 529.922180,286.364777 529.922180,300.549805 
	C528.926514,300.557281 527.930786,300.564728 526.935120,300.572205 
	C526.849854,286.741638 526.764648,272.911072 526.706238,258.625244 
	C527.620117,258.169586 528.507202,258.169159 529.658203,258.081696 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M605.579041,258.003906 
	C605.931519,272.175659 605.931519,286.331146 605.931519,300.486603 
	C605.065430,300.520264 604.199341,300.553894 603.333252,300.587524 
	C603.134277,297.021912 602.935303,293.456268 602.715698,288.987396 
	C602.645203,279.056915 602.595215,270.029694 602.647705,260.666351 
	C602.758545,259.626221 602.766785,258.922241 602.775085,258.218231 
	C602.775085,258.218231 602.948669,258.045624 603.034668,257.956604 
	C603.822693,257.907593 604.524597,257.947601 605.579041,258.003906 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M717.189392,562.904236 
	C717.580688,562.929810 717.972046,562.955444 718.656372,562.995239 
	C718.952515,578.689819 718.955566,594.370117 718.671387,610.535400 
	C717.995605,611.048096 717.607117,611.075867 717.110779,610.634277 
	C717.065125,594.411438 717.127258,578.657837 717.189392,562.904236 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M720.974976,769.002930 
	C720.630798,764.396240 720.309509,759.787598 719.934937,755.183411 
	C719.655701,751.751526 719.307495,748.325256 719.016602,744.443848 
	C719.588806,744.309021 720.542175,744.548523 720.620544,744.956177 
	C722.807251,756.335388 724.905334,767.731628 727.009155,779.561951 
	C727.000244,779.998840 726.961853,780.036560 726.651978,779.864807 
	C724.613098,776.766663 722.883972,773.840210 721.114502,770.596375 
	C721.041016,769.853577 721.007996,769.428284 720.974976,769.002930 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M865.506531,263.982758 
	C865.364868,268.363800 864.827637,272.743134 864.290466,277.122437 
	C863.896729,277.130707 863.502991,277.138947 863.109314,277.147217 
	C862.151733,272.227539 860.800171,267.342590 860.362976,262.377075 
	C860.021973,258.504303 858.293945,257.711609 854.940918,257.897369 
	C851.297974,258.099152 847.635803,257.952637 843.521667,258.013336 
	C843.474670,257.425140 843.876709,256.229736 844.302979,256.220978 
	C850.296326,256.098633 856.293030,256.137909 862.298096,256.538818 
	C863.358887,259.607758 859.391113,263.917267 865.506531,263.982758 
M863.077515,267.520325 
	C863.223328,267.766571 863.369080,268.012817 863.514954,268.259033 
	C863.649963,268.005219 863.946045,267.689514 863.878540,267.513519 
	C863.797363,267.302338 863.406128,267.210297 863.077515,267.520325 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M601.171753,467.099365 
	C606.374573,466.123444 611.843445,465.441467 617.324646,464.880524 
	C621.539856,464.449158 625.770691,464.170074 630.381836,463.689697 
	C631.572998,467.143341 630.125977,469.010712 626.461243,468.891083 
	C619.073303,468.649933 611.688171,468.317230 604.303894,467.978241 
	C603.344177,467.934143 602.396362,467.629395 601.171753,467.099365 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M742.933899,297.605347 
	C735.888733,298.295349 735.853455,298.272522 735.849548,291.490662 
	C735.843323,280.670319 735.852173,269.850006 735.875854,258.571167 
	C736.597229,259.664337 737.877991,261.206360 737.903992,262.769257 
	C738.082092,273.499237 737.997498,284.233612 737.997498,294.886810 
	C739.797485,295.752228 741.329285,296.488708 742.933899,297.605347 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M717.218567,611.103577 
	C717.607117,611.075867 717.995605,611.048096 718.675476,611.003174 
	C719.310364,613.693359 719.929932,616.399475 719.942444,619.108337 
	C719.972229,625.547913 719.714722,631.988892 718.978027,638.703491 
	C717.994629,638.950562 717.606567,638.923462 717.171143,638.439209 
	C717.155396,629.022461 717.186951,620.063049 717.218567,611.103577 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M557.337646,831.940430 
	C557.686768,825.596069 558.444824,819.201477 559.092896,812.795715 
	C559.458923,809.177917 559.643982,805.541870 559.964722,801.456360 
	C560.900330,803.520508 562.509277,806.043274 562.506042,808.564026 
	C562.500061,813.251587 561.818176,817.989929 560.909119,822.606995 
	C560.282959,825.787170 558.800293,828.798645 557.337646,831.940430 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M416.126282,465.151917 
	C425.251740,465.258484 434.375214,465.783875 443.779114,466.581512 
	C442.384003,467.236389 440.714935,467.912323 439.031860,467.949066 
	C432.954620,468.081909 426.865234,468.163635 420.797577,467.887604 
	C419.207977,467.815308 417.680389,466.380035 416.126282,465.151917 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M495.313965,939.948975 
	C497.856842,937.361328 500.811676,934.821838 503.726868,932.237549 
	C508.264191,928.215210 512.774536,924.162354 517.589966,919.954956 
	C516.000793,923.077332 514.690918,926.944946 512.096619,929.517151 
	C508.499146,933.083984 503.989044,935.754456 499.752808,938.636902 
	C498.623505,939.405334 497.067993,939.547363 495.313965,939.948975 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M418.054810,957.322632 
	C426.706635,956.930420 435.406982,956.894592 444.545715,956.881958 
	C444.322906,957.751770 443.766876,959.208252 442.983002,959.343445 
	C438.763702,960.071045 434.444672,961.095398 430.243774,960.805298 
	C426.144562,960.522217 422.146057,958.780457 418.054810,957.322632 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M717.218445,638.896423 
	C717.606567,638.923462 717.994629,638.950562 718.673706,638.991821 
	C718.962585,650.687500 718.960571,662.369019 718.671265,674.535400 
	C717.995483,675.048096 717.606995,675.075806 717.110596,674.634277 
	C717.074707,662.408813 717.146606,650.652649 717.218445,638.896423 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M636.007874,265.043579 
	C641.792786,264.999847 638.928650,269.878174 640.535217,272.978241 
	C643.481995,272.978241 646.774536,272.978241 650.525757,273.017487 
	C648.640747,273.539856 646.297119,274.023010 643.550415,274.589233 
	C643.643555,275.433319 643.758911,276.478943 643.820496,277.915588 
	C643.373047,278.272095 642.979370,278.237579 642.040283,277.992065 
	C634.080322,272.717621 633.535828,271.453674 636.007874,265.043579 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M632.038818,782.032288 
	C633.402222,781.355408 634.760132,780.667603 636.523254,779.957397 
	C636.928406,779.935059 637.090759,780.072693 637.031616,780.408142 
	C635.739319,782.217834 634.612305,783.799683 633.250488,785.143616 
	C630.549133,787.809814 627.718323,790.344910 624.729736,792.987305 
	C624.518433,793.040405 624.105591,793.179993 623.714233,793.101562 
	C620.180603,793.914795 617.038330,794.806580 613.896118,795.698303 
	C614.838745,794.690613 615.781372,793.682861 616.867065,792.407349 
	C617.176270,792.115234 617.342407,792.091064 618.117798,792.024048 
	C622.164124,789.670166 625.601257,787.359253 629.038330,785.048279 
	C629.038330,785.048279 629.017090,785.004883 629.370605,784.987793 
	C630.496582,783.993225 631.268982,783.015686 632.041382,782.038147 
	C632.041382,782.038147 632.033447,782.021301 632.038818,782.032288 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M561.272949,520.936951 
	C561.677429,513.155823 562.486938,505.338562 563.570068,497.229431 
	C564.228271,498.610962 564.980774,500.295563 564.929993,501.955597 
	C564.765137,507.338806 564.411438,512.723755 563.865662,518.081055 
	C563.763367,519.084839 562.438660,519.964111 561.272949,520.936951 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M447.072815,467.379974 
	C454.148041,467.278473 460.519562,469.659821 466.279236,474.385193 
	C465.502136,475.029114 464.362122,475.864899 463.723755,475.579285 
	C458.146912,473.084290 452.659729,470.388733 447.072815,467.379974 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M577.046631,475.029236 
	C582.314697,472.148712 587.768799,469.556458 593.613159,466.999420 
	C590.826294,468.795471 587.698608,470.654358 584.455688,472.283997 
	C582.127747,473.453827 579.645935,474.317352 577.046631,475.029236 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M542.299805,884.958984 
	C544.060181,880.120300 546.186218,875.253296 548.563232,870.156250 
	C549.203796,870.760803 550.155823,871.898254 549.894958,872.382935 
	C547.608032,876.631653 545.104736,880.763916 542.299805,884.958984 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M553.372559,852.956909 
	C554.099548,847.177612 555.199097,841.355286 556.550781,835.228760 
	C558.919312,839.566101 557.193359,849.719604 553.372559,852.956909 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M474.020264,479.289551 
	C476.990814,483.694214 479.956085,488.485596 482.902893,493.646057 
	C481.410767,492.636292 479.498138,491.503540 478.557404,489.825867 
	C476.751892,486.606018 475.497803,483.077026 474.020264,479.289551 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M718.957642,479.524780 
	C718.568909,480.021759 718.180481,480.051117 717.395081,479.621185 
	C717.072327,472.739807 717.146545,466.317657 717.510254,459.906555 
	C718.184021,459.951385 718.568298,459.985199 718.952515,460.018982 
	C718.954285,466.365021 718.956116,472.711090 718.957642,479.524780 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M363.072968,927.302246 
	C366.744568,930.684570 370.378693,934.462585 374.084412,938.582092 
	C367.082214,937.223816 363.816254,933.926941 363.072968,927.302246 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M565.199158,491.938934 
	C566.982483,487.698578 569.148438,483.442993 571.624390,479.030884 
	C571.599792,480.423737 571.366638,482.003998 570.906067,483.515015 
	C570.258301,485.640289 569.441833,487.714172 568.299683,490.930176 
	C568.302185,490.929260 566.941956,491.426483 565.199158,491.938934 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M717.218506,675.103577 
	C717.606995,675.075806 717.995483,675.048096 718.675354,675.003174 
	C719.167419,678.805908 719.368103,682.625854 719.267883,686.729980 
	C718.578918,686.982117 718.190857,686.950073 717.463623,686.458679 
	C717.155762,682.367432 717.187134,678.735535 717.218506,675.103577 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M458.970093,926.579895 
	C455.090118,924.155945 451.169098,921.393860 447.142609,918.312622 
	C452.931458,917.959900 455.192688,923.164246 458.970093,926.579895 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M560.812500,445.878540 
	C555.584961,446.380951 550.084290,446.543884 544.253052,446.454224 
	C549.461426,445.980804 555.000366,445.759918 560.812500,445.878540 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M549.351807,867.947327 
	C550.106628,863.827026 551.207947,859.654053 552.557190,855.204590 
	C556.922058,860.484863 552.868286,864.083862 549.351807,867.947327 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M882.861511,257.872131 
	C877.921448,258.198547 872.965027,258.099182 867.552673,258.056152 
	C867.226135,257.646912 867.369568,256.770752 867.482971,256.774658 
	C872.605347,256.950134 877.725159,257.202820 882.861511,257.872131 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M535.058167,898.874512 
	C536.841675,894.907776 539.035645,890.989380 541.525818,886.875732 
	C541.572449,891.730774 539.204285,895.681641 535.058167,898.874512 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M526.257812,910.910156 
	C528.314026,907.571167 530.741821,904.233459 533.504883,900.787415 
	C532.148376,904.591553 532.090271,909.655212 526.257812,910.910156 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M651.349976,304.933838 
	C652.971619,298.267456 653.877686,291.568115 650.669189,284.533142 
	C651.113708,284.210449 651.808105,284.089722 652.057800,284.368011 
	C655.065308,287.719635 654.816956,301.064209 651.349976,304.933838 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M354.692352,904.038147 
	C356.068359,907.741760 357.092316,911.474792 358.102966,915.594849 
	C354.157379,914.009216 351.797668,906.510864 354.692352,904.038147 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M414.604187,451.034058 
	C414.992096,454.354340 415.033020,457.714966 415.059692,461.532623 
	C414.420288,461.801575 413.589233,461.804962 413.205444,461.392334 
	C411.508453,459.567871 412.220276,453.191040 414.604187,451.034058 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M474.760010,918.028748 
	C472.015717,920.717468 468.885498,923.395081 465.397858,926.117859 
	C468.151672,923.447876 471.262878,920.732788 474.760010,918.028748 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M526.794922,257.838440 
	C522.835999,258.152802 518.881226,258.050415 514.465149,257.939850 
	C516.480896,255.401764 523.076721,255.138870 526.794922,257.838440 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M442.861877,907.930908 
	C441.248505,905.505859 439.698639,902.618530 438.003357,899.403870 
	C444.226440,899.048401 442.842590,903.701294 442.861877,907.930908 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M542.792786,257.839813 
	C538.830811,258.154449 534.873413,258.052460 530.454224,257.946899 
	C532.467773,255.402634 539.069458,255.134460 542.792786,257.839813 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M628.650146,785.033752 
	C625.601257,787.359253 622.164124,789.670166 618.368652,792.002563 
	C621.427490,789.689026 624.844727,787.354126 628.650146,785.033752 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M508.826111,258.011169 
	C505.073090,258.250702 501.322052,258.063019 497.216614,257.664490 
	C500.875488,255.231155 504.868713,254.856552 508.826111,258.011169 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M618.825989,258.029175 
	C615.160645,258.256866 611.505005,258.056000 607.418457,257.684174 
	C610.955261,255.051788 614.905579,254.954315 618.825989,258.029175 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M486.396637,945.001953 
	C488.721313,943.291321 491.446136,941.585510 494.546631,939.887085 
	C493.360168,943.420593 490.735931,945.258667 486.396637,945.001953 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M788.516663,810.928284 
	C788.094055,807.297241 788.048279,803.557800 788.018311,799.404541 
	C791.679932,802.689819 790.284302,806.755005 788.516663,810.928284 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M742.941772,791.659973 
	C741.310059,791.634766 739.628052,791.263611 737.467285,790.553833 
	C735.998718,789.141418 735.008972,788.067627 734.019287,786.993835 
	C737.973389,786.387024 740.658508,788.386169 742.941772,791.659973 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M429.084045,880.895508 
	C427.132935,878.352722 425.481049,875.614929 423.723694,872.533325 
	C427.189758,873.908997 429.346252,876.586975 429.084045,880.895508 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M519.159790,918.913513 
	C520.945923,916.306396 523.119568,913.736755 525.602051,911.040649 
	C526.243286,915.535950 522.805054,917.171997 519.159790,918.913513 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M529.815857,445.871033 
	C526.903870,446.361664 523.729614,446.522980 520.242310,446.434082 
	C523.137390,445.969818 526.345459,445.755768 529.815857,445.871033 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M357.676941,883.043091 
	C357.267212,885.530273 356.485199,888.051208 355.457520,890.827637 
	C352.860687,887.617432 353.460907,884.923218 357.676941,883.043091 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M434.870483,890.943665 
	C433.186066,888.838562 431.557281,886.319214 429.864838,883.449341 
	C434.773651,883.324341 433.765961,887.674438 434.870483,890.943665 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M632.038208,452.902710 
	C630.262634,450.987823 628.801086,448.873779 627.119019,446.516479 
	C631.014221,445.137024 632.652771,447.069000 632.038208,452.902710 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M363.029816,871.157104 
	C362.234161,873.178345 361.030487,875.134521 359.496765,877.208679 
	C360.764648,875.495789 356.718384,870.536987 363.029816,871.157104 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M733.751709,786.874756 
	C735.008972,788.067627 735.998718,789.141418 737.094849,790.512207 
	C737.201233,790.809326 737.037170,790.967102 736.619873,790.973511 
	C734.060913,789.060913 731.919189,787.141846 730.142883,785.152649 
	C731.003784,785.056396 731.499146,785.030212 731.994568,785.004089 
	C732.491089,785.587952 732.987671,786.171814 733.751709,786.874756 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M842.718506,258.012390 
	C843.012817,259.450226 842.957764,260.836578 842.785767,262.623413 
	C841.298401,261.512787 839.927917,260.001617 838.557434,258.490479 
	C838.826599,258.147614 839.095825,257.804718 839.364990,257.461853 
	C840.366394,257.628204 841.367737,257.794556 842.718506,258.012390 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M630.920532,256.943085 
	C631.136108,257.253052 631.253357,257.663940 631.016052,257.851562 
	C630.465942,258.265625 630.153137,258.492004 629.607910,258.712494 
	C629.375427,258.706573 629.058838,259.047211 628.738037,259.044128 
	C627.954102,259.445160 627.490967,259.849243 626.728882,260.240265 
	C626.039368,260.304749 625.648865,260.382324 625.258301,260.459900 
	C624.767578,255.901688 627.826965,256.396454 630.920532,256.943085 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M396.958435,858.634399 
	C395.283875,859.024902 393.568970,859.053833 391.427307,859.038391 
	C392.492706,854.812317 394.480591,854.697815 396.958435,858.634399 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M652.113525,264.861084 
	C650.725769,263.378662 649.647095,261.685669 648.408264,259.690430 
	C652.077942,259.208038 653.639526,260.827240 652.113525,264.861084 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M419.912903,867.849609 
	C418.532104,867.127319 417.192963,866.004150 415.758759,864.555786 
	C419.545013,862.035095 420.029114,864.369446 419.912903,867.849609 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M719.231445,459.791870 
	C718.568298,459.985199 718.184021,459.951385 717.459900,459.509460 
	C717.151062,457.767212 717.182068,456.433105 717.213135,455.098969 
	C717.601135,455.071869 717.989197,455.044739 718.667969,455.000671 
	C719.142578,456.510742 719.326416,458.037781 719.231445,459.791870 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M390.033234,950.245300 
	C391.097931,950.477112 392.144928,951.132385 393.228455,952.109070 
	C392.635590,953.211670 392.006134,953.992920 391.376709,954.774170 
	C390.923004,953.405701 390.469269,952.037292 390.033234,950.245300 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M481.020691,948.228638 
	C482.424835,946.953979 483.869263,946.005127 485.655579,945.026184 
	C485.222443,947.389893 484.635071,950.044067 481.020691,948.228638 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M359.006836,921.019043 
	C360.554688,922.622864 361.812927,924.406860 363.099060,926.538940 
	C361.657013,924.991089 360.187073,923.095215 359.006836,921.019043 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M731.993652,784.630676 
	C731.499146,785.030212 731.003784,785.056396 729.958557,785.086243 
	C729.408752,785.089966 729.044800,784.940491 729.043945,784.594727 
	C728.349365,782.844788 727.655579,781.440674 726.961792,780.036560 
	C726.961853,780.036560 727.000244,779.998840 727.019775,779.979370 
	C728.690430,781.392395 730.341553,782.824829 731.993652,784.630676 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M888.201050,269.764099 
	C888.457886,268.345337 888.820190,267.282990 889.182495,266.220642 
	C889.664062,266.409882 890.145569,266.599152 890.627075,266.788391 
	C890.244385,268.058960 893.419495,270.784790 888.201050,269.764099 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M470.964478,952.859375 
	C472.114410,951.796204 473.642792,950.819946 475.529541,949.803589 
	C475.766388,952.814331 474.713257,954.534729 470.964478,952.859375 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M417.363098,449.582428 
	C416.907196,449.441162 416.451294,449.299866 415.634155,449.158264 
	C414.225220,444.845490 416.923950,445.188477 419.881287,445.907349 
	C419.131409,447.053314 418.184479,447.899902 417.280579,449.061951 
	C417.323639,449.377411 417.363098,449.582428 417.363098,449.582428 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M397.059387,953.211914 
	C398.595032,953.287231 400.152130,953.739136 401.899200,954.491455 
	C399.808441,956.934814 398.195801,956.297180 397.059387,953.211914 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M372.988739,863.147949 
	C372.190552,864.493835 370.977570,865.777466 369.426208,867.118408 
	C368.820404,864.593933 369.592926,862.898560 372.988739,863.147949 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M783.798645,256.772644 
	C782.607056,257.742920 781.195190,258.431519 779.439331,259.131104 
	C779.293945,256.067291 780.984741,255.515869 783.798645,256.772644 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M382.040405,945.313171 
	C383.399567,946.002380 384.789734,947.051208 386.314026,948.433167 
	C384.989227,947.735168 383.530304,946.703979 382.040405,945.313171 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M462.116394,955.094360 
	C463.371521,954.154968 464.822540,953.514404 466.630310,952.897339 
	C465.428802,953.744873 463.870544,954.569031 462.116394,955.094360 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M647.926636,258.955078 
	C646.416138,258.800293 644.902344,258.255890 643.161133,257.455383 
	C645.316956,255.024780 646.905273,255.753708 647.926636,258.955078 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M866.773438,258.092773 
	C866.930847,259.242096 866.747070,260.384491 866.322876,261.775757 
	C863.485046,260.470764 863.452148,259.144592 866.773438,258.092773 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M447.143250,957.111389 
	C448.368500,956.547607 449.810913,956.289490 451.633911,956.099243 
	C450.463135,956.583801 448.911743,957.000427 447.143250,957.111389 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M412.940552,862.768555 
	C411.612091,862.373291 410.261169,861.595825 408.807007,860.486328 
	C410.108551,860.898376 411.513336,861.642395 412.940552,862.768555 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M817.838745,256.825256 
	C816.928284,257.762939 815.821716,258.420258 814.387085,259.084290 
	C815.253479,258.242310 816.448120,257.393616 817.838745,256.825256 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M446.858276,915.967407 
	C445.867859,915.137268 444.912018,913.926941 443.947662,912.363770 
	C444.923737,913.203064 445.908295,914.395142 446.858276,915.967407 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M643.691895,451.070557 
	C644.026794,452.086609 644.011597,453.153137 643.966309,454.619446 
	C641.250549,454.099121 641.319336,452.759033 643.691895,451.070557 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M404.013184,859.517334 
	C403.155823,859.783447 402.288177,859.722168 401.177704,859.440308 
	C401.587830,858.598083 402.240784,857.976501 402.893738,857.354858 
	C403.263458,857.966492 403.633179,858.578186 404.013184,859.517334 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M647.956909,280.747284 
	C646.648193,280.374481 645.314880,279.623566 643.862305,278.560913 
	C645.139465,278.955872 646.535889,279.662506 647.956909,280.747284 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M377.150391,942.024780 
	C378.172058,942.479431 379.158813,943.325073 380.259338,944.490845 
	C379.287201,944.012512 378.201324,943.214111 377.150391,942.024780 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M883.433411,263.766510 
	C883.104858,262.586334 883.010498,261.205780 882.974060,259.411804 
	C883.243896,260.520966 883.455750,262.043579 883.433411,263.766510 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M640.719238,256.638000 
	C638.948303,256.936829 636.914001,256.973053 634.452026,256.921692 
	C636.168152,256.681213 638.312012,256.528320 640.719238,256.638000 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M631.694824,782.058350 
	C631.268982,783.015686 630.496582,783.993225 629.386230,785.010498 
	C629.814941,784.059692 630.581665,783.069153 631.694824,782.058350 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M354.712463,894.049988 
	C355.069336,894.757629 355.085602,895.505920 355.054321,896.626038 
	C352.741425,896.477356 352.393860,895.537964 354.712463,894.049988 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M791.706970,256.569763 
	C790.267883,256.869507 788.566284,256.930115 786.447021,256.902771 
	C787.834351,256.653412 789.639404,256.492035 791.706970,256.569763 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M830.959473,257.772461 
	C830.137695,257.912750 829.290161,257.714355 828.216248,257.276917 
	C828.971130,257.169800 829.952393,257.301788 830.959473,257.772461 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M652.342102,271.753815 
	C651.968384,270.591705 651.861328,269.200165 651.883057,267.406067 
	C652.210754,268.510437 652.409790,270.017426 652.342102,271.753815 
z"/>
<path fill="#01BA79" opacity="1.000000" stroke="none" 
	d="
M625.255981,260.630768 
	C625.648865,260.382324 626.039368,260.304749 626.683228,260.516968 
	C626.371704,261.192535 625.806885,261.578278 625.242065,261.963989 
	C625.242065,261.963989 625.129272,261.478119 625.053467,261.240601 
	C624.977661,261.003082 625.253723,260.801636 625.255981,260.630768 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M471.095123,477.187073 
	C471.756989,477.286560 472.430939,477.752045 473.226227,478.521484 
	C472.600769,478.401337 471.854004,477.977203 471.095123,477.187073 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M457.072754,956.051147 
	C457.428650,955.545410 457.932098,955.298950 458.726196,955.103333 
	C458.417969,955.539612 457.819122,955.925049 457.072754,956.051147 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M755.003967,795.505981 
	C754.488892,795.677124 753.918396,795.579468 753.176270,795.235474 
	C753.652588,795.071777 754.300537,795.154419 755.003967,795.505981 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M786.968384,797.636475 
	C786.355164,797.539978 785.697327,797.118652 784.967896,796.386353 
	C785.572083,796.487427 786.247925,796.899536 786.968384,797.636475 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M825.753296,256.598328 
	C824.917786,256.877075 823.862610,256.925232 822.417847,256.894104 
	C823.196655,256.665833 824.365112,256.516785 825.753296,256.598328 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M785.177002,814.249268 
	C785.475098,813.694641 785.942200,813.368530 786.711182,813.036865 
	C786.457275,813.513367 785.901672,813.995605 785.177002,814.249268 
z"/>
<path fill="#F2FCF7" opacity="1.000000" stroke="none" 
	d="
M378.999634,860.156494 
	C378.811035,860.810669 378.252533,861.436035 377.363708,862.104980 
	C377.565491,861.474854 378.097626,860.801208 378.999634,860.156494 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M883.392578,267.824463 
	C883.130920,267.538940 883.064392,267.102020 883.093262,266.343933 
	C883.321655,266.572845 883.454712,267.122925 883.392578,267.824463 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M883.176880,307.866089 
	C882.946228,307.535034 882.953491,307.091614 883.079651,306.334900 
	C883.270569,306.598969 883.342651,307.176361 883.176880,307.866089 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M619.223022,259.901978 
	C618.986633,259.836670 618.918640,259.676636 618.903198,259.261169 
	C619.100952,259.272919 619.246094,259.540070 619.223022,259.901978 
z"/>
<path fill="#EAFBF8" opacity="1.000000" stroke="none" 
	d="
M649.844971,283.006714 
	C649.581055,283.064667 649.269104,282.833069 648.935303,282.298401 
	C649.208008,282.235901 649.502502,282.476532 649.844971,283.006714 
z"/>
<path fill="#03CDE1" opacity="1.000000" stroke="none" 
	d="
M709.829102,293.908020 
	C709.503601,298.512451 712.325623,298.489471 715.094238,298.172974 
	C718.788330,297.750763 720.075562,299.108582 719.954163,302.895020 
	C719.842285,306.385071 719.550659,308.559143 715.141113,308.279846 
	C709.371155,307.914368 703.560608,308.190308 697.348755,308.190308 
	C697.348755,291.412567 697.348755,275.062958 697.348755,258.331055 
	C704.272583,258.331055 711.146973,258.331055 718.544556,258.331055 
	C718.632080,260.153656 718.688660,261.922028 718.808289,263.686066 
	C718.991455,266.387146 718.398254,268.256714 715.058167,268.019836 
	C712.383484,267.830200 709.482605,267.516937 709.830994,271.816589 
	C710.089478,275.006714 708.916992,278.903656 714.567139,278.165588 
	C717.675049,277.759613 717.954712,280.111542 717.852417,282.456360 
	C717.762390,284.518738 718.420898,287.329285 714.955444,286.935822 
	C709.482483,286.314423 709.505249,289.683014 709.829102,293.908020 
z"/>
<path fill="#10CFED" opacity="1.000000" stroke="none" 
	d="
M757.025452,257.930908 
	C759.676941,257.926178 761.839233,257.920654 764.001526,257.924347 
	C769.695984,257.934113 770.242920,258.614044 769.986450,264.166473 
	C769.863037,266.839844 768.913635,268.199677 766.113159,268.085815 
	C764.652466,268.026459 763.181885,268.209930 761.339417,268.302643 
	C761.339417,271.443817 761.339417,274.338440 761.339417,277.685211 
	C763.574158,277.919250 765.818054,278.154266 768.507324,278.435944 
	C768.507324,280.968109 768.507324,283.525604 768.507324,286.685120 
	C766.394958,286.878876 764.017822,287.096893 761.314331,287.344849 
	C761.314331,290.785156 761.314331,294.023743 761.314331,297.651825 
	C762.586792,297.823029 763.835693,298.088135 765.093872,298.143707 
	C770.885742,298.399445 771.594666,298.965820 770.779053,304.550018 
	C770.581848,305.899689 768.650513,307.936523 767.397705,308.027985 
	C762.103760,308.414551 756.749390,308.401489 751.453735,308.022949 
	C750.206970,307.933807 748.104675,305.870575 748.084473,304.686737 
	C747.838135,290.220276 747.824890,275.747101 748.052124,261.280396 
	C748.070007,260.140503 750.158630,258.593018 751.595032,258.038910 
	C753.040405,257.481262 754.873230,257.927795 757.025452,257.930908 
z"/>
<path fill="#10C0AA" opacity="1.000000" stroke="none" 
	d="
M666.019775,257.985413 
	C673.684937,257.961853 681.348999,258.142822 688.998962,257.893402 
	C692.687195,257.773193 694.027710,258.915863 693.941284,262.662140 
	C693.862366,266.082092 693.657043,268.733978 689.206787,268.068359 
	C688.743652,267.999084 688.237000,268.220825 687.162781,268.412292 
	C687.055786,270.735321 686.858459,273.154205 686.848206,275.573853 
	C686.808838,284.897369 686.703491,294.223602 686.889343,303.543549 
	C686.964111,307.290741 685.613525,308.452362 681.924866,308.301239 
	C672.558289,307.917603 673.962891,309.202942 673.878113,300.347412 
	C673.800110,292.189941 673.867371,284.031189 673.853760,275.872986 
	C673.841431,268.489685 673.831360,268.489716 665.925415,267.610687 
	C665.925415,264.786469 665.925415,261.861877 665.941528,258.481995 
	C665.957703,258.026672 666.012939,258.013031 666.019775,257.985413 
z"/>
<path fill="#10A723" opacity="1.000000" stroke="none" 
	d="
M571.998352,257.976685 
	C576.098633,257.951019 580.203064,257.951019 584.594849,257.951019 
	C584.594849,274.832214 584.594849,291.176422 584.594849,308.257507 
	C581.557312,308.257507 578.623901,308.082733 575.720886,308.304779 
	C572.575256,308.545410 571.741394,307.213470 571.767273,304.255035 
	C571.899536,289.147491 571.870239,274.038544 571.924316,258.462097 
	C571.951172,257.994141 572.002441,258.002319 571.998352,257.976685 
z"/>
<path fill="#10A723" opacity="1.000000" stroke="none" 
	d="
M602.545288,261.002472 
	C602.595215,270.029694 602.645203,279.056915 602.602295,288.546234 
	C599.703369,293.504944 600.417114,297.925995 602.862732,302.266571 
	C603.539001,303.466858 604.417114,304.553436 605.295776,305.827240 
	C598.898865,308.033203 594.691345,305.909668 590.788391,299.132935 
	C590.337830,298.580872 590.195984,298.097870 590.378662,296.936432 
	C590.791626,295.441467 590.955017,294.625122 590.956848,293.808411 
	C590.978882,283.711487 590.935913,273.614258 591.005920,263.517731 
	C591.028503,260.251709 594.633301,257.347443 596.221191,259.191315 
	C598.310974,261.617920 600.425964,260.590851 602.545288,261.002472 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M624.963379,262.252319 
	C625.806885,261.578278 626.371704,261.192535 626.982178,260.530090 
	C627.490967,259.849243 627.954102,259.445160 628.841431,259.201538 
	C629.457153,259.147461 629.648743,258.932922 629.840332,258.718384 
	C630.153137,258.492004 630.465942,258.265625 631.078857,258.055786 
	C633.782043,258.072357 636.185181,258.072357 639.709534,258.072357 
	C638.103271,260.834625 637.090454,262.576355 636.042725,264.680847 
	C633.535828,271.453674 634.080322,272.717621 641.744019,278.035583 
	C639.900024,281.055634 642.696838,286.917908 636.059204,287.098511 
	C623.810547,282.677460 620.037354,276.128082 624.963379,262.252319 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M638.665161,301.262207 
	C639.058899,303.582458 639.028503,305.832855 639.003174,308.447998 
	C626.788635,309.959259 621.344849,303.688446 623.437805,290.459900 
	C624.796082,290.364716 626.236206,290.195099 627.678406,290.174500 
	C634.369751,290.079132 634.178711,290.114532 634.910400,296.903992 
	C635.075378,298.435333 637.082214,299.768158 638.665161,301.262207 
z"/>
<path fill="#02B358" opacity="1.000000" stroke="none" 
	d="
M602.647766,260.666351 
	C600.425964,260.590851 598.310974,261.617920 596.221191,259.191315 
	C594.633301,257.347443 591.028503,260.251709 591.005920,263.517731 
	C590.935913,273.614258 590.978882,283.711487 590.956848,293.808411 
	C590.955017,294.625122 590.791626,295.441467 590.442871,296.560364 
	C590.182434,284.134430 590.182434,271.406128 590.182434,258.078308 
	C594.085388,258.078308 597.983887,258.078308 602.328735,258.148254 
	C602.766785,258.922241 602.758545,259.626221 602.647766,260.666351 
z"/>
<path fill="#02B358" opacity="1.000000" stroke="none" 
	d="
M487.995331,506.009766 
	C487.093903,500.352722 486.271301,494.681702 485.272125,489.041992 
	C483.857391,481.056732 477.576721,477.066193 471.562714,473.063049 
	C458.130310,464.121887 443.063263,461.629578 427.218628,462.021912 
	C415.924469,462.301575 415.794922,461.929840 417.425018,449.989563 
	C417.363098,449.582428 417.323639,449.377411 417.430664,449.379669 
	C435.679321,449.425598 453.820892,449.469269 472.586487,449.931458 
	C474.965576,450.588684 476.717529,451.006317 478.476257,451.036774 
	C488.978333,451.218719 499.481995,451.311371 510.000793,451.814453 
	C510.949249,456.481262 513.220947,460.986206 512.563416,465.012848 
	C510.601318,477.029175 514.793091,489.628174 509.002197,501.200165 
	C508.196381,523.763611 509.667603,546.304382 507.352173,568.938721 
	C505.076904,591.180603 505.625824,613.714661 505.021606,636.122864 
	C504.605042,651.572449 504.370575,667.026855 503.987793,682.477478 
	C503.724640,693.098267 503.617004,703.734375 502.906891,714.327881 
	C502.547424,719.690308 500.392548,724.941284 500.111511,730.298401 
	C499.150055,748.623230 498.663330,766.972839 497.975586,785.312256 
	C497.944824,786.132385 497.582520,786.949158 497.606140,787.760742 
	C497.720398,791.686035 497.741089,795.626099 498.124481,799.528503 
	C498.569305,804.056824 499.738007,808.541382 499.857880,813.064331 
	C499.933624,815.922668 498.251434,818.790955 498.071594,821.698975 
	C497.243011,835.098328 496.700836,848.515137 495.941162,861.919250 
	C495.866943,863.228760 495.007172,864.493774 494.512054,865.779480 
	C494.038361,865.692200 493.564697,865.604919 493.091003,865.517700 
	C492.727753,862.436279 492.070068,859.356750 492.050446,856.273132 
	C491.945862,839.864685 492.001495,823.455261 492.001038,807.046143 
	C492.000244,779.808472 491.998596,752.570801 491.998077,725.333130 
	C491.997711,706.506226 492.049622,687.678894 491.889526,668.853333 
	C491.878296,667.532043 490.303772,666.223999 489.456177,664.909790 
	C489.446228,662.592773 489.436279,660.275757 489.904480,657.452637 
	C490.920959,655.205750 491.945465,653.457825 491.920013,651.725342 
	C491.730194,638.798157 491.261475,625.875183 491.053802,612.948120 
	C491.006531,610.004944 491.948914,607.045349 491.896088,604.103027 
	C491.749084,595.917664 491.159576,587.739685 491.038879,579.554565 
	C490.879517,568.750549 491.129150,557.940491 490.966217,547.136597 
	C490.776245,534.542297 490.379791,521.949890 489.913116,509.362610 
	C489.870850,508.222870 488.662292,507.126343 487.995331,506.009766 
M443.242340,460.055481 
	C448.990326,461.449982 454.926666,462.341003 460.426514,464.398743 
	C464.800720,466.035278 465.992889,462.436188 468.158997,460.754761 
	C468.752289,460.294220 467.741425,456.732880 466.575409,455.341248 
	C465.486847,454.042053 463.233978,453.143646 461.460205,453.085449 
	C448.663147,452.665436 435.858032,452.460754 423.054047,452.324982 
	C421.720581,452.310852 420.377655,453.191254 418.838043,453.726471 
	C419.503235,456.039612 420.036743,457.894867 420.658264,460.056152 
	C428.009003,460.056152 435.151215,460.056152 443.242340,460.055481 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M489.243134,665.260132 
	C490.303772,666.223999 491.878296,667.532043 491.889526,668.853333 
	C492.049622,687.678894 491.997711,706.506226 491.998077,725.333130 
	C491.998596,752.570801 492.000244,779.808472 492.001038,807.046143 
	C492.001495,823.455261 491.945862,839.864685 492.050446,856.273132 
	C492.070068,859.356750 492.727753,862.436279 493.091003,865.517700 
	C493.564697,865.604919 494.038361,865.692200 494.512054,865.779480 
	C495.007172,864.493774 495.866943,863.228760 495.941162,861.919250 
	C496.700836,848.515137 497.243011,835.098328 498.071594,821.698975 
	C498.251434,818.790955 499.933624,815.922668 499.857880,813.064331 
	C499.738007,808.541382 498.569305,804.056824 498.124481,799.528503 
	C497.741089,795.626099 497.720398,791.686035 497.606140,787.760742 
	C497.582520,786.949158 497.944824,786.132385 497.975586,785.312256 
	C498.663330,766.972839 499.150055,748.623230 500.111511,730.298401 
	C500.392548,724.941284 502.547424,719.690308 502.906891,714.327881 
	C503.617004,703.734375 503.724640,693.098267 503.987793,682.477478 
	C504.370575,667.026855 504.605042,651.572449 505.021606,636.122864 
	C505.625824,613.714661 505.076904,591.180603 507.352173,568.938721 
	C509.667603,546.304382 508.196381,523.763611 509.002197,501.200165 
	C514.793091,489.628174 510.601318,477.029175 512.563416,465.012848 
	C513.220947,460.986206 510.949249,456.481262 510.438293,451.700073 
	C513.247498,451.269562 515.634949,451.333893 518.098267,451.697693 
	C519.117737,452.340820 520.061279,452.684540 521.003662,453.425659 
	C522.749817,458.597992 523.160034,463.229156 520.317322,467.869751 
	C519.632202,468.988220 520.063416,470.797546 520.001831,472.289978 
	C519.382812,487.297974 520.603577,502.299469 519.183289,517.350220 
	C517.705322,533.013184 517.686401,548.812988 516.997437,564.551575 
	C516.272400,581.116211 516.924072,597.643066 515.442139,614.291565 
	C513.119995,640.378601 513.688599,666.721619 512.989380,692.955078 
	C512.724060,702.908936 512.599548,712.876709 511.949158,722.807861 
	C510.852417,739.554443 512.776855,756.199707 509.626434,773.180603 
	C506.456024,790.269348 508.351410,808.295044 507.994690,825.909485 
	C507.685181,841.192810 507.330719,856.475098 506.994904,871.757874 
	C506.983948,872.257080 506.975800,872.757324 506.938934,873.255005 
	C505.656921,890.557556 504.371582,907.859741 503.039856,925.504028 
	C502.718414,926.942932 502.443909,928.039734 502.169373,929.136597 
	C502.169373,929.136597 502.064056,929.052429 501.735107,929.075317 
	C499.270691,931.047546 497.135223,932.996948 494.999695,934.946289 
	C494.623840,935.046692 494.248016,935.147156 493.220276,935.190186 
	C492.059875,935.121216 491.551392,935.109619 490.993011,934.714233 
	C490.848694,932.748535 490.359406,931.065491 490.733765,929.603577 
	C491.872437,925.156860 491.916138,920.989502 489.702515,916.768738 
	C489.340698,916.078979 490.760834,913.680237 491.878479,913.130249 
	C495.633331,911.282593 496.031738,909.887817 493.598267,906.928894 
	C491.075348,906.928894 489.025574,906.928894 487.036621,906.557495 
	C487.544189,902.999695 487.990906,899.813416 488.437592,896.627075 
	C488.557495,860.867798 488.723297,825.108643 488.785004,789.349304 
	C488.853302,749.761292 488.824127,710.173218 488.848877,670.585144 
	C488.849915,668.926880 488.967041,667.268677 489.243134,665.260132 
z"/>
<path fill="#02B358" opacity="1.000000" stroke="none" 
	d="
M486.975800,906.928894 
	C489.025574,906.928894 491.075348,906.928894 493.598267,906.928894 
	C496.031738,909.887817 495.633331,911.282593 491.878479,913.130249 
	C490.760834,913.680237 489.340698,916.078979 489.702515,916.768738 
	C491.916138,920.989502 491.872437,925.156860 490.733765,929.603577 
	C490.359406,931.065491 490.848694,932.748535 490.831421,935.009338 
	C489.286591,936.739685 487.798157,937.724731 486.434937,938.860168 
	C484.897186,940.141113 483.472107,941.557251 481.997986,942.914551 
	C481.618866,943.041016 481.239746,943.167480 480.214050,943.237793 
	C478.781708,943.832153 477.995850,944.482788 477.210022,945.133423 
	C477.210052,945.133423 476.753845,945.321594 476.199615,945.274353 
	C475.089691,945.760132 474.534027,946.293091 473.978333,946.826111 
	C468.628693,948.319946 463.350830,950.198669 457.911743,951.212769 
	C450.672089,952.562622 443.306915,953.239502 435.581665,953.736389 
	C437.227203,951.348328 441.483490,952.217834 441.642120,947.806641 
	C441.836517,942.400696 442.453613,937.010986 442.709198,931.605835 
	C442.812103,929.430298 442.969116,927.022766 442.197845,925.079468 
	C437.912170,914.281616 433.506805,903.523376 428.769409,892.918152 
	C427.579987,890.255554 425.019928,888.205200 423.039917,885.521606 
	C418.810944,874.337646 411.749237,867.095459 399.778839,864.865417 
	C392.074005,863.430054 385.281586,864.374329 378.691803,868.570984 
	C375.732513,870.455566 372.308350,871.610107 369.026123,873.022217 
	C368.957062,872.948425 368.824219,872.796082 368.824219,872.796082 
	C371.807953,870.311584 374.443787,867.097534 377.839966,865.477722 
	C388.529816,860.379211 399.772430,859.639343 410.309784,865.440063 
	C418.316589,869.847778 423.259552,877.623108 427.284302,885.683044 
	C432.158020,895.442993 436.491364,905.473511 441.394440,915.217957 
	C445.497375,923.372131 451.179871,930.190369 461.313538,929.828979 
	C465.279633,929.687439 469.171234,927.456543 473.826508,926.064087 
	C475.633698,924.774841 476.711151,923.598633 477.788635,922.422485 
	C477.722565,922.145081 477.815613,921.939575 478.629944,921.836914 
	C479.729156,920.549011 480.266266,919.230164 480.803375,917.911255 
	C480.816620,917.737122 480.829834,917.563049 481.260864,917.132568 
	C485.340393,915.288086 485.425446,915.097656 483.407898,913.003296 
	C483.590027,912.572754 483.772186,912.142212 484.368073,911.309814 
	C485.513123,909.581665 486.244476,908.255310 486.975800,906.928894 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M502.507080,929.097656 
	C502.443909,928.039734 502.718414,926.942932 503.376953,925.435791 
	C508.856323,919.713379 513.951721,914.401245 519.347473,908.928345 
	C521.007629,907.317444 522.642456,906.039551 523.678101,904.386292 
	C527.574402,898.166565 531.666077,892.020386 534.946960,885.476868 
	C538.708374,877.974854 542.320251,870.278503 544.820557,862.298035 
	C547.205627,854.685608 548.368286,846.642029 549.539612,838.709839 
	C550.976196,828.981995 552.208008,819.197449 552.905579,809.393127 
	C553.893188,795.511230 554.696289,781.594788 554.910217,767.682800 
	C556.252930,680.348694 552.437866,592.971436 557.043945,505.665955 
	C557.671204,493.776642 559.759216,482.227722 570.154053,473.897400 
	C575.370422,469.642822 580.320435,465.629639 587.080933,465.086243 
	C582.961975,467.702087 578.719238,470.144531 574.752869,472.974274 
	C568.797913,477.222778 565.176331,482.933929 562.423096,489.916656 
	C557.939575,501.287628 558.192810,513.055969 558.147339,524.636230 
	C557.794678,614.416138 558.013855,704.198120 557.728333,793.978455 
	C557.689453,806.205261 557.146973,818.716064 554.524658,830.584290 
	C550.239746,849.976990 547.204163,869.889526 537.060242,887.609619 
	C528.007996,903.422546 517.091370,917.586975 502.507080,929.097656 
z"/>
<path fill="#10A723" opacity="1.000000" stroke="none" 
	d="
M423.089783,885.873474 
	C425.019928,888.205200 427.579987,890.255554 428.769409,892.918152 
	C433.506805,903.523376 437.912170,914.281616 442.197845,925.079468 
	C442.969116,927.022766 442.812103,929.430298 442.709198,931.605835 
	C442.453613,937.010986 441.836517,942.400696 441.642120,947.806641 
	C441.483490,952.217834 437.227203,951.348328 435.130157,953.636841 
	C429.656677,954.020752 424.220673,954.035034 418.243347,953.703979 
	C417.447205,952.911987 417.192383,952.465332 417.271118,951.876099 
	C421.792480,950.035767 426.742798,948.719604 421.256287,942.901917 
	C420.652435,942.261536 420.930054,940.651917 421.026733,939.511169 
	C421.650421,932.152344 422.507751,924.809021 422.945404,917.440186 
	C423.249329,912.322327 422.844086,907.164368 423.081360,902.039429 
	C423.180573,899.896423 424.123627,897.792480 424.928650,894.739014 
	C424.477753,892.565063 423.783783,889.219299 423.089783,885.873474 
z"/>
<path fill="#10C0AA" opacity="1.000000" stroke="none" 
	d="
M587.476379,465.072845 
	C580.320435,465.629639 575.370422,469.642822 569.799866,473.877136 
	C568.571228,473.111389 567.336243,471.937744 567.570129,471.379272 
	C568.740112,468.585693 570.295349,465.953400 572.061157,462.608826 
	C567.116211,460.036804 569.368103,455.530334 570.191589,451.263428 
	C572.399475,451.392761 574.194397,451.805145 575.983276,451.780365 
	C591.267212,451.568573 606.550110,451.281708 621.833191,451.007172 
	C625.718445,450.937378 626.998840,453.027954 627.065063,456.667175 
	C627.133057,460.398682 625.173096,461.125397 622.025391,461.043976 
	C617.199890,460.919128 612.365967,460.914124 607.541870,461.061523 
	C606.033569,461.107605 604.545227,461.809204 603.047791,462.210693 
	C601.888672,462.358704 600.729492,462.506683 598.864624,462.397400 
	C595.770874,462.951599 593.382812,463.763031 590.994812,464.574463 
	C589.953796,464.736145 588.912842,464.897797 587.476379,465.072845 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M603.471924,462.414581 
	C604.545227,461.809204 606.033569,461.107605 607.541870,461.061523 
	C612.365967,460.914124 617.199890,460.919128 622.025391,461.043976 
	C625.173096,461.125397 627.133057,460.398682 627.065063,456.667175 
	C626.998840,453.027954 625.718445,450.937378 621.833191,451.007172 
	C606.550110,451.281708 591.267212,451.568573 575.983276,451.780365 
	C574.194397,451.805145 572.399475,451.392761 569.753723,451.191254 
	C561.491943,451.458710 554.084106,451.917908 546.675659,451.927246 
	C537.124817,451.939301 527.573486,451.591217 518.022400,451.398224 
	C515.634949,451.333893 513.247498,451.269562 510.422607,451.319580 
	C499.481995,451.311371 488.978333,451.218719 478.476257,451.036774 
	C476.717529,451.006317 474.965576,450.588684 473.028595,450.040466 
	C474.637421,449.519592 476.428131,449.123657 478.218811,449.123871 
	C525.814331,449.129211 573.410095,449.277039 621.005005,449.121277 
	C629.615295,449.093109 629.281067,452.046722 628.466858,459.188721 
	C628.310425,460.560394 624.870789,462.423920 622.847839,462.555634 
	C616.555847,462.965271 610.216858,462.651581 603.471924,462.414581 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M487.926453,506.469391 
	C488.662292,507.126343 489.870850,508.222870 489.913116,509.362610 
	C490.379791,521.949890 490.776245,534.542297 490.966217,547.136597 
	C491.129150,557.940491 490.879517,568.750549 491.038879,579.554565 
	C491.159576,587.739685 491.749084,595.917664 491.896088,604.103027 
	C491.948914,607.045349 491.006531,610.004944 491.053802,612.948120 
	C491.261475,625.875183 491.730194,638.798157 491.920013,651.725342 
	C491.945465,653.457825 490.920959,655.205750 489.930206,657.018188 
	C489.269226,652.309509 489.011597,647.530762 488.862366,642.748596 
	C488.546539,632.630127 488.015869,622.508728 488.057983,612.390625 
	C488.165588,586.521729 488.645050,560.654297 488.748840,534.785400 
	C488.786072,525.501282 488.173584,516.214539 487.926453,506.469391 
z"/>
<path fill="#02B358" opacity="1.000000" stroke="none" 
	d="
M416.937561,952.018677 
	C417.192383,952.465332 417.447205,952.911987 417.775391,953.602234 
	C401.022491,952.140869 386.265625,946.264587 374.041443,933.996033 
	C363.157898,923.072998 357.835327,910.373596 358.946838,895.091187 
	C359.551636,886.775757 361.110016,878.669617 368.484009,872.865601 
	C368.824219,872.796082 368.957062,872.948425 368.979187,873.380859 
	C368.395599,874.954224 367.627350,876.039185 367.211304,877.245544 
	C365.078369,883.430725 361.750824,889.536743 361.206360,895.873718 
	C359.652771,913.955322 368.633972,927.546265 382.364014,938.154358 
	C390.849121,944.710022 400.494232,949.088928 411.425812,950.126343 
	C413.305969,950.304810 415.102417,951.365540 416.937561,952.018677 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M487.036652,906.557495 
	C486.244476,908.255310 485.513123,909.581665 484.453369,910.968079 
	C485.358276,906.348938 486.591583,901.669739 488.131256,896.808838 
	C487.990906,899.813416 487.544189,902.999695 487.036652,906.557495 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M482.354309,942.947266 
	C483.472107,941.557251 484.897186,940.141113 486.434937,938.860168 
	C487.798157,937.724731 489.286591,936.739685 490.881348,935.393066 
	C491.551392,935.109619 492.059875,935.121216 492.935486,935.253601 
	C489.771942,937.909607 486.241272,940.444824 482.354309,942.947266 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M591.379578,464.681061 
	C593.382812,463.763031 595.770874,462.951599 598.427856,462.315796 
	C596.385986,463.256836 594.075134,464.022247 591.379578,464.681061 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M495.350159,934.967529 
	C497.135223,932.996948 499.270691,931.047546 501.770142,929.132935 
	C499.989594,931.108032 497.845093,933.048340 495.350159,934.967529 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M483.161316,913.149475 
	C485.425446,915.097656 485.340393,915.288086 481.322235,916.877930 
	C481.615417,915.684998 482.265045,914.490295 483.161316,913.149475 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M480.501190,918.063232 
	C480.266266,919.230164 479.729156,920.549011 478.810944,921.830811 
	C479.019562,920.600830 479.609283,919.408020 480.501190,918.063232 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M477.446716,922.484985 
	C476.711151,923.598633 475.633698,924.774841 474.148438,925.915283 
	C474.862000,924.768921 475.983398,923.658203 477.446716,922.484985 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M477.535339,945.146362 
	C477.995850,944.482788 478.781708,943.832153 479.929871,943.333679 
	C479.481689,944.043640 478.671173,944.601501 477.535339,945.146362 
z"/>
<path fill="#32C695" opacity="1.000000" stroke="none" 
	d="
M474.281982,946.884277 
	C474.534027,946.293091 475.089691,945.760132 475.962646,945.343140 
	C475.715179,945.953674 475.150391,946.448059 474.281982,946.884277 
z"/>
<path fill="#01D1F1" opacity="1.000000" stroke="none" 
	d="
M678.990051,320.880707 
	C746.102905,320.880707 813.215759,320.880707 881.032104,320.880707 
	C881.763000,327.146454 882.817078,332.997833 883.058350,338.882507 
	C883.650818,353.332703 883.631348,367.809326 884.304749,382.254456 
	C884.968384,396.487549 886.234314,410.691833 887.093689,424.917358 
	C887.216614,426.952209 888.278442,429.907776 884.401123,430.143066 
	C880.930969,430.353638 878.005676,429.771088 877.269470,425.701508 
	C875.772644,417.427734 874.550659,409.094666 872.736694,400.891876 
	C871.863586,396.943359 870.363159,392.972687 868.358459,389.462280 
	C858.239685,371.743561 845.177795,356.922607 824.589539,351.676422 
	C812.324524,348.551117 799.647766,346.048889 787.051208,345.441406 
	C766.105042,344.431396 745.074463,345.162720 724.079468,345.176941 
	C716.638733,345.182007 714.183533,347.647736 714.091919,354.958740 
	C713.798035,378.417450 713.400757,401.875214 713.239014,425.334717 
	C713.213745,428.990265 712.226013,430.312988 708.440857,430.241547 
	C697.614075,430.037109 686.780701,430.183411 675.448486,429.797180 
	C674.829895,428.295074 674.479004,427.153290 674.635559,426.085999 
	C675.345398,421.249176 676.784790,416.447388 676.900391,411.607666 
	C677.455688,388.358826 677.666504,365.101776 678.003601,341.847656 
	C678.015686,341.016998 677.810608,340.059235 678.145996,339.376526 
	C681.123840,333.315369 680.104980,327.106842 678.990051,320.880707 
z"/>
<path fill="#03CDE1" opacity="1.000000" stroke="none" 
	d="
M678.522644,320.868439 
	C680.104980,327.106842 681.123840,333.315369 678.145996,339.376526 
	C677.810608,340.059235 678.015686,341.016998 678.003601,341.847656 
	C677.666504,365.101776 677.455688,388.358826 676.900391,411.607666 
	C676.784790,416.447388 675.345398,421.249176 674.635559,426.085999 
	C674.479004,427.153290 674.829895,428.295074 674.981079,429.778931 
	C668.089539,430.231812 661.161377,430.216644 654.239380,430.415802 
	C649.267700,430.558807 648.799255,427.703430 648.822021,423.673035 
	C648.947144,401.531616 648.997620,379.387543 648.737244,357.248352 
	C648.697021,353.829315 646.916992,350.430695 646.033875,346.306152 
	C647.014465,343.382233 648.786743,341.106842 648.623352,338.980164 
	C648.156372,332.903198 646.902954,326.886627 645.958923,320.846313 
	C656.657715,320.849579 667.356445,320.852875 678.522644,320.868439 
z"/>
<path fill="#10C0AA" opacity="1.000000" stroke="none" 
	d="
M645.530762,320.845581 
	C646.902954,326.886627 648.156372,332.903198 648.623352,338.980164 
	C648.786743,341.106842 647.014465,343.382233 645.966431,345.953308 
	C628.682068,346.856628 611.558228,347.417786 594.432617,347.919586 
	C590.633423,348.030945 586.828186,347.933289 582.575928,347.878540 
	C582.112122,347.493835 581.948059,346.999573 582.105591,346.854492 
	C590.438965,339.179688 586.443909,329.688263 585.975281,320.281708 
	C605.677795,320.175507 625.390137,320.510193 645.530762,320.845581 
z"/>
<path fill="#02B358" opacity="1.000000" stroke="none" 
	d="
M493.118652,398.179749 
	C493.068237,399.521576 493.017822,400.863403 492.605530,402.653107 
	C491.803040,404.390045 491.362427,405.679138 490.921814,406.968201 
	C489.634644,413.428864 488.309052,419.882233 487.075623,426.353149 
	C486.400482,429.894989 484.497528,431.585480 480.807129,431.019745 
	C477.019592,430.439026 477.815369,427.451721 477.979950,425.169220 
	C478.834961,413.308533 480.257263,401.477020 480.748749,389.604980 
	C481.270782,376.994293 480.749878,364.343292 481.105499,351.721802 
	C481.361450,342.639038 482.374817,333.579620 482.911102,324.501587 
	C483.103760,321.240051 485.065521,320.840881 487.646820,320.856842 
	C496.454376,320.911377 505.262238,320.911133 514.581177,321.336182 
	C515.728210,324.229279 516.991699,326.747833 516.875000,329.200714 
	C516.595154,335.081482 515.680969,340.933044 514.993591,346.792969 
	C514.681458,349.453949 514.300049,352.106781 513.924866,354.954224 
	C517.012268,357.062195 517.012268,357.062195 516.979614,362.914825 
	C516.776184,363.191406 516.572693,363.467987 515.779541,363.871674 
	C512.818665,366.379913 510.447510,368.761078 508.076355,371.142212 
	C505.131653,375.848236 502.048859,380.475555 499.303833,385.295258 
	C498.036133,387.521027 497.428253,390.122559 495.882996,392.837219 
	C494.536041,394.807007 493.827332,396.493378 493.118652,398.179749 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M517.290405,362.791412 
	C517.012268,357.062195 517.012268,357.062195 513.924866,354.954224 
	C514.300049,352.106781 514.681458,349.453949 514.993591,346.792969 
	C515.680969,340.933044 516.595154,335.081482 516.875000,329.200714 
	C516.991699,326.747833 515.728210,324.229279 515.050232,321.348877 
	C522.695557,320.960114 530.382996,320.963043 538.572449,321.342896 
	C539.604675,323.257385 540.689331,324.840942 540.566223,326.324219 
	C540.106934,331.854797 539.108337,337.339050 538.589905,342.866730 
	C538.176880,347.270721 537.125427,350.720154 531.979553,351.731934 
	C530.765442,351.970612 529.979431,354.386993 528.996460,355.801575 
	C525.198059,358.090393 521.399597,360.379181 517.290405,362.791412 
z"/>
<path fill="#01BA79" opacity="1.000000" stroke="none" 
	d="
M529.344604,355.805725 
	C529.979431,354.386993 530.765442,351.970612 531.979553,351.731934 
	C537.125427,350.720154 538.176880,347.270721 538.589905,342.866730 
	C539.108337,337.339050 540.106934,331.854797 540.566223,326.324219 
	C540.689331,324.840942 539.604675,323.257385 539.041626,321.349976 
	C544.674194,320.980225 550.340210,320.933685 556.005005,320.996216 
	C560.038696,321.040710 562.026123,323.759735 561.766785,327.741180 
	C561.558350,330.940521 561.807373,334.231384 562.342773,337.398926 
	C562.816650,340.202179 559.624512,346.125305 556.577942,347.079895 
	C555.471191,347.426697 554.287048,348.010406 553.208008,347.885101 
	C544.095581,346.826965 536.963989,351.498840 529.344604,355.805725 
z"/>
<path fill="#62DFE8" opacity="1.000000" stroke="none" 
	d="
M566.575684,349.474731 
	C563.146484,350.072235 559.420471,350.417694 555.404236,350.503235 
	C558.835693,349.903107 562.557251,349.562866 566.575684,349.474731 
z"/>
<path fill="#62DFE8" opacity="1.000000" stroke="none" 
	d="
M552.719238,350.239746 
	C549.620239,351.455566 546.120300,352.554626 542.269348,353.433167 
	C545.385010,352.182770 548.851685,351.152893 552.719238,350.239746 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M508.417633,371.072815 
	C510.447510,368.761078 512.818665,366.379913 515.545227,363.986877 
	C513.520020,366.317780 511.139465,368.660553 508.417633,371.072815 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M493.442108,398.066345 
	C493.827332,396.493378 494.536041,394.807007 495.584778,393.064026 
	C495.205048,394.655945 494.485321,396.304443 493.442108,398.066345 
z"/>
<path fill="#05B560" opacity="1.000000" stroke="none" 
	d="
M491.198059,406.790955 
	C491.362427,405.679138 491.803040,404.390045 492.551666,403.052551 
	C492.397888,404.207336 491.936127,405.410522 491.198059,406.790955 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M821.757568,291.938171 
	C822.009888,282.685059 821.325806,273.862854 822.164673,265.138641 
	C822.868835,265.166168 823.572937,265.193726 824.277100,265.221283 
	C824.277100,277.074860 824.277100,288.928436 824.277100,300.782013 
	C823.688416,300.837860 823.099792,300.893738 822.511108,300.949585 
	C822.258728,298.109406 822.006409,295.269257 821.757568,291.938171 
z"/>
<path fill="#4CDAE8" opacity="1.000000" stroke="none" 
	d="
M613.584351,795.779175 
	C617.038330,794.806580 620.180603,793.914795 623.644287,793.420166 
	C622.326782,794.910828 620.687805,796.004333 618.648254,797.118408 
	C606.750122,799.843140 595.160339,801.973938 583.297424,800.768372 
	C580.088684,800.442383 577.973083,800.408630 577.997864,804.306274 
	C578.018677,807.582458 577.151733,811.190430 582.604248,811.070190 
	C594.085999,810.817017 605.574707,810.878662 617.258057,810.916870 
	C617.726746,811.182007 617.902588,811.413574 617.983154,811.717468 
	C617.988220,811.954346 617.993347,812.191162 618.002441,812.784302 
	C606.512146,813.106201 595.013794,813.218872 583.526550,812.915588 
	C581.178711,812.853638 577.236389,811.650085 576.875366,810.242615 
	C576.034973,806.966614 576.609863,803.178223 577.335205,799.750549 
	C577.526489,798.846802 580.334656,798.047058 581.973022,797.979919 
	C587.961365,797.734741 593.963196,797.819092 600.778320,797.820679 
	C602.724365,797.544678 603.852112,797.227356 604.979858,796.909973 
	C606.021667,796.879272 607.063477,796.848511 608.830322,796.845337 
	C610.366577,796.566223 611.177795,796.259521 611.989014,795.952759 
	C612.416870,795.921814 612.844727,795.890930 613.584351,795.779175 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M604.768738,796.655273 
	C603.852112,797.227356 602.724365,797.544678 601.229492,797.735229 
	C602.094116,797.205688 603.325867,796.803101 604.768738,796.655273 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M611.805786,795.703369 
	C611.177795,796.259521 610.366577,796.566223 609.212952,796.765076 
	C609.787903,796.256165 610.705200,795.855042 611.805786,795.703369 
z"/>
<path fill="#4CDAE8" opacity="1.000000" stroke="none" 
	d="
M729.044800,784.940491 
	C729.044800,784.940491 729.408752,785.089966 729.593079,785.156372 
	C731.919189,787.141846 734.060913,789.060913 736.539307,791.053833 
	C742.972290,795.743958 750.062500,797.478638 757.492920,797.946594 
	C765.274963,798.436707 773.073120,798.709656 780.868225,798.940063 
	C785.681824,799.082336 786.007080,802.412537 785.923950,805.874268 
	C785.846924,809.080261 785.647278,812.151855 780.912659,812.143188 
	C729.477417,812.049011 678.041870,812.072449 626.606445,812.055054 
	C626.280823,812.054932 625.955200,811.931763 625.516907,811.270874 
	C627.015686,810.475891 628.626038,810.113586 630.238770,810.102661 
	C640.160522,810.035217 650.082764,810.047119 660.472900,810.043945 
	C699.602234,810.052551 738.263428,810.049744 776.924683,810.046875 
	C777.757874,810.046814 778.894836,810.392761 779.366882,809.979614 
	C780.740112,808.777466 782.709351,807.374512 782.890320,805.864014 
	C783.355408,801.982605 780.116638,801.951538 777.424805,801.964661 
	C767.450073,802.013428 757.632080,801.564636 747.775208,799.224365 
	C730.532654,795.130554 718.606079,783.897156 716.209961,766.423889 
	C714.458069,753.648010 713.479736,740.694580 713.230164,727.799561 
	C712.769043,703.978455 713.094604,680.142151 713.094299,656.312134 
	C713.093567,590.654663 713.094788,524.997131 713.091858,459.339661 
	C713.091614,452.925873 713.080811,452.909454 706.504211,452.903870 
	C695.672424,452.894684 684.840576,452.909058 673.584473,452.902466 
	C667.218323,452.891846 661.276367,452.885345 655.334473,452.894440 
	C648.922852,452.904266 648.934204,452.910645 648.911865,459.479523 
	C648.581665,556.339600 648.246704,653.199646 647.918579,750.059692 
	C647.888000,759.075623 646.234802,767.785217 642.691101,776.062317 
	C642.143677,777.340820 640.332947,778.078430 639.088440,778.689941 
	C643.119995,768.239441 646.059265,757.961060 644.845154,746.918640 
	C644.868286,746.496155 644.891418,746.073730 644.880310,745.335815 
	C645.204224,743.139038 645.874329,741.257935 645.875732,739.376221 
	C645.945801,645.249084 645.960388,551.121826 645.974182,456.994598 
	C645.975281,449.494781 646.449341,448.977203 653.846558,448.978943 
	C672.338745,448.983276 690.831909,449.099609 709.322510,448.933319 
	C713.520874,448.895569 715.991760,450.337494 717.082642,454.737518 
	C717.182068,456.433105 717.151062,457.767212 717.170349,459.498444 
	C717.146545,466.317657 717.072327,472.739807 717.102600,479.631287 
	C717.171997,507.396515 717.136902,534.692383 717.145630,562.446228 
	C717.127258,578.657837 717.065125,594.411438 717.110779,610.634277 
	C717.186951,620.063049 717.155396,629.022461 717.171143,638.439209 
	C717.146606,650.652649 717.074707,662.408813 717.110657,674.634277 
	C717.187134,678.735535 717.155762,682.367432 717.171448,686.447876 
	C717.482300,710.903198 717.660828,734.911621 718.111511,758.914917 
	C718.171875,762.127197 719.608215,765.313599 720.691040,768.757446 
	C721.007996,769.428284 721.041016,769.853577 721.036011,770.991699 
	C722.363220,776.917175 723.188293,782.458252 729.044800,784.940491 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M644.473633,747.036499 
	C646.059265,757.961060 643.119995,768.239441 639.069519,778.689453 
	C639.068604,779.065247 639.072205,779.094238 638.796082,779.144653 
	C638.043518,779.487610 637.567139,779.780151 637.090759,780.072693 
	C637.090759,780.072693 636.928406,779.935059 636.844482,779.865723 
	C638.662781,772.230652 640.644653,764.683594 642.430176,757.090332 
	C643.197388,753.827637 643.557129,750.469238 644.473633,747.036499 
z"/>
<path fill="#03CDE1" opacity="1.000000" stroke="none" 
	d="
M660.004883,810.032654 
	C650.082764,810.047119 640.160522,810.035217 630.238770,810.102661 
	C628.626038,810.113586 627.015686,810.475891 625.160767,811.048950 
	C622.869385,811.559265 620.821533,811.696472 618.378418,811.775574 
	C617.902588,811.413574 617.726746,811.182007 617.180786,810.615845 
	C616.528992,809.252869 615.654419,808.168945 615.861328,807.362610 
	C616.750549,803.896545 617.957581,800.512024 619.048828,797.097839 
	C620.687805,796.004333 622.326782,794.910828 624.035645,793.498657 
	C624.105591,793.179993 624.518433,793.040405 625.104248,793.025146 
	C632.177002,790.319946 637.440125,786.453003 639.072205,779.094238 
	C639.072205,779.094238 639.068604,779.065247 639.087524,779.065674 
	C640.332947,778.078430 642.143677,777.340820 642.691101,776.062317 
	C646.234802,767.785217 647.888000,759.075623 647.918579,750.059692 
	C648.246704,653.199646 648.581665,556.339600 648.911865,459.479523 
	C648.934204,452.910645 648.922852,452.904266 655.334473,452.894440 
	C661.276367,452.885345 667.218323,452.891846 673.549805,453.267395 
	C673.948669,455.057312 673.739807,456.514069 674.006958,457.877563 
	C674.913086,462.502594 676.685547,467.068420 676.859436,471.704437 
	C677.283752,483.018311 677.005920,494.358887 676.987976,505.688660 
	C676.985596,507.181213 676.981445,508.694092 676.753174,510.162567 
	C675.542175,517.953613 673.672485,525.692627 673.125488,533.526001 
	C672.039917,549.072754 671.500366,564.664429 671.041382,580.246582 
	C670.239014,607.481750 669.810486,634.728271 668.945618,661.961182 
	C668.440491,677.868469 668.937561,693.721497 667.448303,709.714722 
	C664.867554,737.428833 665.097229,765.399902 663.916809,793.253357 
	C663.694458,798.501099 665.038086,804.372925 660.102905,808.571899 
	C659.843628,808.792542 660.027405,809.533752 660.004883,810.032654 
z"/>
<path fill="#ADF1F7" opacity="1.000000" stroke="none" 
	d="
M729.043945,784.594727 
	C723.188293,782.458252 722.363220,776.917175 721.076477,771.309143 
	C722.883972,773.840210 724.613098,776.766663 726.651978,779.864807 
	C727.655579,781.440674 728.349365,782.844788 729.043945,784.594727 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M863.113403,267.293518 
	C863.406128,267.210297 863.797363,267.302338 863.878540,267.513519 
	C863.946045,267.689514 863.649963,268.005219 863.514893,268.259033 
	C863.369080,268.012817 863.223328,267.766571 863.113403,267.293518 
z"/>
<path fill="#4CDAE8" opacity="1.000000" stroke="none" 
	d="
M638.796082,779.144653 
	C637.440125,786.453003 632.177002,790.319946 625.315491,792.972046 
	C627.718323,790.344910 630.549133,787.809814 633.250488,785.143616 
	C634.612305,783.799683 635.739319,782.217834 637.031616,780.408142 
	C637.567139,779.780151 638.043518,779.487610 638.796082,779.144653 
z"/>
<path fill="#01BA79" opacity="1.000000" stroke="none" 
	d="
M629.607910,258.712463 
	C629.648743,258.932922 629.457153,259.147461 629.162231,259.204590 
	C629.058838,259.047211 629.375427,258.706573 629.607910,258.712463 
z"/>
<path fill="#10A723" opacity="1.000000" stroke="none" 
	d="
M442.767883,460.055817 
	C435.151215,460.056152 428.009003,460.056152 420.658264,460.056152 
	C420.036743,457.894867 419.503235,456.039612 418.838043,453.726471 
	C420.377655,453.191254 421.720581,452.310852 423.054047,452.324982 
	C435.858032,452.460754 448.663147,452.665436 461.460205,453.085449 
	C463.233978,453.143646 465.486847,454.042053 466.575409,455.341248 
	C467.741425,456.732880 468.752289,460.294220 468.158997,460.754761 
	C465.992889,462.436188 464.800720,466.035278 460.426514,464.398743 
	C454.926666,462.341003 448.990326,461.449982 442.767883,460.055817 
z"/>
<path fill="#01BA79" opacity="1.000000" stroke="none" 
	d="
M518.098267,451.697693 
	C527.573486,451.591217 537.124817,451.939301 546.675659,451.927246 
	C554.084106,451.917908 561.491943,451.458710 569.337952,451.271667 
	C569.368103,455.530334 567.116211,460.036804 572.061157,462.608826 
	C570.295349,465.953400 568.740112,468.585693 567.570129,471.379272 
	C567.336243,471.937744 568.571228,473.111389 569.483032,474.021637 
	C559.759216,482.227722 557.671204,493.776642 557.043945,505.665955 
	C552.437866,592.971436 556.252930,680.348694 554.910217,767.682800 
	C554.696289,781.594788 553.893188,795.511230 552.905579,809.393127 
	C552.208008,819.197449 550.976196,828.981995 549.539612,838.709839 
	C548.368286,846.642029 547.205627,854.685608 544.820557,862.298035 
	C542.320251,870.278503 538.708374,877.974854 534.946960,885.476868 
	C531.666077,892.020386 527.574402,898.166565 523.678101,904.386292 
	C522.642456,906.039551 521.007629,907.317444 519.330811,908.483398 
	C519.497620,905.461975 520.101562,902.739502 520.439514,899.984497 
	C521.068359,894.857361 521.900085,889.718689 522.007446,884.571472 
	C522.243713,873.249939 521.704651,861.904236 522.181030,850.597839 
	C522.723816,837.715271 524.186218,824.874207 524.948547,811.997314 
	C525.536865,802.059631 525.463928,792.083130 526.032837,782.143799 
	C527.308105,759.864258 524.861511,737.644104 528.406433,715.177368 
	C531.562744,695.174133 529.511658,674.369507 530.050415,653.926270 
	C530.461060,638.345154 531.141052,622.765198 532.042908,607.204590 
	C533.016174,590.410828 530.859009,573.688171 534.446899,556.707947 
	C537.210022,543.631165 534.857544,529.500610 535.035217,515.841919 
	C535.183105,504.475037 535.650391,493.111023 536.078979,481.749603 
	C536.297485,475.957458 536.529663,470.151550 537.134399,464.391632 
	C538.036865,455.796387 535.814392,452.998749 527.501953,452.999725 
	C525.336243,452.999969 523.170593,453.018250 521.004883,453.028229 
	C520.061279,452.684540 519.117737,452.340820 518.098267,451.697693 
z"/>
<path fill="#01B76C" opacity="1.000000" stroke="none" 
	d="
M521.003662,453.425659 
	C523.170593,453.018250 525.336243,452.999969 527.501953,452.999725 
	C535.814392,452.998749 538.036865,455.796387 537.134399,464.391632 
	C536.529663,470.151550 536.297485,475.957458 536.078979,481.749603 
	C535.650391,493.111023 535.183105,504.475037 535.035217,515.841919 
	C534.857544,529.500610 537.210022,543.631165 534.446899,556.707947 
	C530.859009,573.688171 533.016174,590.410828 532.042908,607.204590 
	C531.141052,622.765198 530.461060,638.345154 530.050415,653.926270 
	C529.511658,674.369507 531.562744,695.174133 528.406433,715.177368 
	C524.861511,737.644104 527.308105,759.864258 526.032837,782.143799 
	C525.463928,792.083130 525.536865,802.059631 524.948547,811.997314 
	C524.186218,824.874207 522.723816,837.715271 522.181030,850.597839 
	C521.704651,861.904236 522.243713,873.249939 522.007446,884.571472 
	C521.900085,889.718689 521.068359,894.857361 520.439514,899.984497 
	C520.101562,902.739502 519.497620,905.461975 519.030396,908.644043 
	C513.951721,914.401245 508.856323,919.713379 503.423889,925.093811 
	C504.371582,907.859741 505.656921,890.557556 506.938934,873.255005 
	C506.975800,872.757324 506.983948,872.257080 506.994904,871.757874 
	C507.330719,856.475098 507.685181,841.192810 507.994690,825.909485 
	C508.351410,808.295044 506.456024,790.269348 509.626434,773.180603 
	C512.776855,756.199707 510.852417,739.554443 511.949158,722.807861 
	C512.599548,712.876709 512.724060,702.908936 512.989380,692.955078 
	C513.688599,666.721619 513.119995,640.378601 515.442139,614.291565 
	C516.924072,597.643066 516.272400,581.116211 516.997437,564.551575 
	C517.686401,548.812988 517.705322,533.013184 519.183289,517.350220 
	C520.603577,502.299469 519.382812,487.297974 520.001831,472.289978 
	C520.063416,470.797546 519.632202,468.988220 520.317322,467.869751 
	C523.160034,463.229156 522.749817,458.597992 521.003662,453.425659 
z"/>
<path fill="#02A718" opacity="1.000000" stroke="none" 
	d="
M417.271118,951.876099 
	C415.102417,951.365540 413.305969,950.304810 411.425812,950.126343 
	C400.494232,949.088928 390.849121,944.710022 382.364014,938.154358 
	C368.633972,927.546265 359.652771,913.955322 361.206360,895.873718 
	C361.750824,889.536743 365.078369,883.430725 367.211304,877.245544 
	C367.627350,876.039185 368.395599,874.954224 369.048248,873.454590 
	C372.308350,871.610107 375.732513,870.455566 378.691803,868.570984 
	C385.281586,864.374329 392.074005,863.430054 399.778839,864.865417 
	C411.749237,867.095459 418.810944,874.337646 423.039917,885.521606 
	C423.783783,889.219299 424.477753,892.565063 424.928650,894.739014 
	C424.123627,897.792480 423.180573,899.896423 423.081360,902.039429 
	C422.844086,907.164368 423.249329,912.322327 422.945404,917.440186 
	C422.507751,924.809021 421.650421,932.152344 421.026733,939.511169 
	C420.930054,940.651917 420.652435,942.261536 421.256287,942.901917 
	C426.742798,948.719604 421.792480,950.035767 417.271118,951.876099 
z"/>
<path fill="#10C0AA" opacity="1.000000" stroke="none" 
	d="
M618.648254,797.118408 
	C617.957581,800.512024 616.750549,803.896545 615.861328,807.362610 
	C615.654419,808.168945 616.528992,809.252869 616.983276,810.510071 
	C605.574707,810.878662 594.085999,810.817017 582.604248,811.070190 
	C577.151733,811.190430 578.018677,807.582458 577.997864,804.306274 
	C577.973083,800.408630 580.088684,800.442383 583.297424,800.768372 
	C595.160339,801.973938 606.750122,799.843140 618.648254,797.118408 
z"/>
<path fill="#01D1F1" opacity="1.000000" stroke="none" 
	d="
M660.472900,810.043945 
	C660.027405,809.533752 659.843628,808.792542 660.102905,808.571899 
	C665.038086,804.372925 663.694458,798.501099 663.916809,793.253357 
	C665.097229,765.399902 664.867554,737.428833 667.448303,709.714722 
	C668.937561,693.721497 668.440491,677.868469 668.945618,661.961182 
	C669.810486,634.728271 670.239014,607.481750 671.041382,580.246582 
	C671.500366,564.664429 672.039917,549.072754 673.125488,533.526001 
	C673.672485,525.692627 675.542175,517.953613 676.753174,510.162567 
	C676.981445,508.694092 676.985596,507.181213 676.987976,505.688660 
	C677.005920,494.358887 677.283752,483.018311 676.859436,471.704437 
	C676.685547,467.068420 674.913086,462.502594 674.006958,457.877563 
	C673.739807,456.514069 673.948669,455.057312 673.974121,453.278381 
	C684.840576,452.909058 695.672424,452.894684 706.504211,452.903870 
	C713.080811,452.909454 713.091614,452.925873 713.091858,459.339661 
	C713.094788,524.997131 713.093567,590.654663 713.094299,656.312134 
	C713.094604,680.142151 712.769043,703.978455 713.230164,727.799561 
	C713.479736,740.694580 714.458069,753.648010 716.209961,766.423889 
	C718.606079,783.897156 730.532654,795.130554 747.775208,799.224365 
	C757.632080,801.564636 767.450073,802.013428 777.424805,801.964661 
	C780.116638,801.951538 783.355408,801.982605 782.890320,805.864014 
	C782.709351,807.374512 780.740112,808.777466 779.366882,809.979614 
	C778.894836,810.392761 777.757874,810.046814 776.924683,810.046875 
	C738.263428,810.049744 699.602234,810.052551 660.472900,810.043945 
z"/>


        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}